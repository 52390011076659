import React, { memo } from "react";
import Text from "./Text";
import theme from "../../../lib/theme";
import { Typography } from "theme";

interface InputLabelProps {
  label?: string | number;
  typography?: keyof Typography;
}

function InputLabel({ label, typography = "16" }: InputLabelProps) {
  return (
    <Text typography={typography} color={theme.color.black}>
      {label}
    </Text>
  );
}

export default memo(InputLabel);
