import React from "react";

interface FetchMoreProps {
  fetchMoreRef: (node?: Element | null | undefined) => void;
}

function FetchMore({ fetchMoreRef }: FetchMoreProps) {
  return (
    <div
      ref={fetchMoreRef}
      style={{ width: 1, height: 1, visibility: "hidden" }}
    />
  );
}

export default FetchMore;
