import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  GetPoliciesDocument,
  PolicyType,
  useGetLatestPolicyQuery,
  useUpdatePolicyAsAdminMutation,
} from "../../../lib/apollo/graphql/generated";
import Text from "../../common/components/Text";
import theme from "../../../lib/theme";
import Editor from "../../common/components/Editor";
import Flex from "../../common/components/Flex";
import Button from "../../common/components/Button";

function UpdatePolicy() {
  const navigate = useNavigate();
  const params = useParams();

  const type = params?.type as PolicyType;

  const [body, setBody] = useState("");

  const { loading, data } = useGetLatestPolicyQuery({
    variables: {
      type: type,
    },
    onCompleted: (data) => {
      if (data?.getLatestPolicy) {
        setBody(data?.getLatestPolicy?.body || "");
      }
    },
  });

  const [updatePolicyAsAdmin, { loading: updateLoading }] =
    useUpdatePolicyAsAdminMutation({
      onCompleted: (data) => {
        if (data?.updatePolicyAsAdmin) {
          navigate(-1);
        }
      },
      refetchQueries: () => [
        {
          query: GetPoliciesDocument,
          variables: {
            type,
          },
        },
      ],
    });

  function onChange(value: string) {
    setBody(value);
  }

  function onSubmit() {
    updatePolicyAsAdmin({
      variables: {
        policyInput: {
          type,
          body,
        },
      },
    });
  }

  return (
    <div>
      <Flex alignItems="center" justifyContent="space-between">
        <Text typography="20B" marginBottom={theme.spacing[24]}>
          {type === PolicyType?.Privacy
            ? "개인정보처리방침 작성"
            : "이용약관 작성"}
        </Text>

        <Button
          text="Submit"
          disabled={!body || updateLoading}
          onClick={onSubmit}
        />
      </Flex>

      <Editor value={body} onChange={onChange} />
    </div>
  );
}

export default UpdatePolicy;
