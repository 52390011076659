import {
  GetCategoriesDocument,
  useGetCategoriesQuery,
  useUpdateCategoryAsAdminMutation,
  useUpdateCategoryNameAsAdminMutation,
} from "../../../lib/apollo/graphql/generated";

function useGiftCategories() {
  const { loading, data } = useGetCategoriesQuery();

  const [updateCategoryAsAdmin, { loading: updateLoading }] =
    useUpdateCategoryAsAdminMutation({
      refetchQueries,
    });
  const [updateCategoryNameAsAdmin, { loading: updateNameLoading }] =
    useUpdateCategoryNameAsAdminMutation({ refetchQueries });

  function refetchQueries() {
    return [{ query: GetCategoriesDocument }];
  }

  function onUpdate({
    id,
    order,
    active,
  }: {
    id: string | null;
    order: number;
    active: boolean;
  }) {
    updateCategoryAsAdmin({
      variables: {
        categoryInput: {
          id,
          order: Number(order),
          active,
        },
      },
    });
  }

  function onNameUpdate({
    id,
    languageCode,
    name,
    parentId,
  }: {
    id: string | null;
    languageCode: string;
    name: string;
    parentId: string;
  }) {
    updateCategoryNameAsAdmin({
      variables: {
        categoryNameInput: {
          id,
          name,
          languageCode,
          categoryId: parentId,
        },
      },
    });
  }

  return {
    models: {
      loading,
      updateLoading: updateLoading || updateNameLoading,
      data: data?.getCategories,
    },
    operations: {
      onUpdate,
      onNameUpdate,
    },
  };
}

export default useGiftCategories;
