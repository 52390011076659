import React from "react";
import logo from "../../../assets/images/logo.png";
import useAuthContainer from "./AuthContainer.hook";
import Flex from "../../common/components/Flex";
import theme from "../../../lib/theme";
import Text from "../../common/components/Text";
import Input from "../../common/components/Input";
import Button from "../../common/components/Button";

function AuthContainer() {
  const {
    models: { state },
    operations: { onChange, onSubmit },
  } = useAuthContainer();

  const { email, password } = state;

  return (
    <Flex
      width="100vw"
      height="100vh"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      gap={theme.spacing[24]}
    >
      <Text typography="28B">KEEIFT ADMIN</Text>
      <Flex
        alignItems="center"
        justifyContent="center"
        width="60%"
        padding={`${theme.spacing[12]}px 0px`}
        backgroundColor="rgba(0,0,0,0.1)"
      >
        <Flex flex={1} alignItems="center" justifyContent="center">
          {/* <img src={logo}  /> */}
          <Text typography="28B">KEEIFT</Text>
        </Flex>
        <Flex
          flex={1}
          flexDirection="column"
          gap={theme.spacing[8]}
          alignItems="center"
          justifyContent="center"
          padding={`${theme.spacing[12]}px`}
          borderLeft={`1px solid ${theme.color.gray3}`}
        >
          <Input
            placeholder="Email"
            value={email}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              onChange(e, "email")
            }
          />
          <Input
            placeholder="Password"
            type="password"
            value={password}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              onChange(e, "password")
            }
          />
          <Button text="SIGN IN" onClick={onSubmit} />
        </Flex>
      </Flex>
    </Flex>
  );
}

export default AuthContainer;
