import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  GetAnnouncementsDocument,
  useGetAnnouncementLazyQuery,
  useUpdateAnnouncementAsAdminMutation,
} from "../../../lib/apollo/graphql/generated";
import Flex from "../../common/components/Flex";
import theme from "../../../lib/theme";
import Text from "../../common/components/Text";
import Input from "../../common/components/Input";
import Editor from "../../common/components/Editor";
import Button from "../../common/components/Button";
import { SCREENS } from "../../../lib/route/utils/router";

function UpdateAnnouncement() {
  const navigate = useNavigate();
  const params = useParams();

  const announcementId = params?.announcementId;

  const [state, setState] = useState({
    id: "",
    title: "",
    body: "",
    active: true,
  });

  const [getAnnouncement] = useGetAnnouncementLazyQuery({
    onCompleted: (data) => {
      if (data?.getAnnouncement) {
        const { id, title, body, active } = data?.getAnnouncement;

        setState({
          id,
          title,
          body,
          active,
        });
      }
    },
  });

  const [updateAnnouncementAsAdmin, { loading }] =
    useUpdateAnnouncementAsAdminMutation({
      onCompleted: (data) => {
        if (data?.updateAnnouncementAsAdmin) {
          navigate(SCREENS.ANNOUNCEMENT);
        }
      },

      refetchQueries:
        announcementId !== "null"
          ? () => [
              {
                query: GetAnnouncementsDocument,
                variables: {
                  activeOnly: false,
                  first: 20,
                },
              },
            ]
          : undefined,
    });

  useEffect(() => {
    if (announcementId !== "null") {
      getAnnouncement({ variables: { announcementId: announcementId! } });
    }
  }, [announcementId]);

  function onInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { value } = e.target;

    setState((prev) => ({
      ...prev,
      title: value,
    }));
  }

  function toggleActive(e: React.ChangeEvent<HTMLInputElement>) {
    const { checked } = e.target;

    setState((prev) => ({
      ...prev,
      active: checked,
    }));
  }

  function onBodyChange(value: string) {
    setState((prev) => ({
      ...prev,
      body: value,
    }));
  }

  function onSubmit() {
    const { id, title, body, active } = state;

    updateAnnouncementAsAdmin({
      variables: {
        announcementInput: {
          id,
          title,
          body,
          active,
        },
      },
    });
  }

  const { id, title, body, active } = state;

  return (
    <Flex
      flexDirection="column"
      padding={theme.spacing[24]}
      gap={theme.spacing[24]}
    >
      <Flex alignItems="center" justifyContent="space-between">
        <Text typography="28B">공지 수정</Text>
        <Button
          text="수정"
          disabled={!title || !body || loading}
          onClick={onSubmit}
        />
      </Flex>
      {!!id && <Input label="ID" value={id} disabled />}
      <Input label="제목" value={title} onChange={onInputChange} />
      <Input
        label="공개여부"
        type="checkbox"
        checked={active}
        onChange={toggleActive}
      />
      <Editor value={body} mediaFolder="public" onChange={onBodyChange} />
    </Flex>
  );
}

export default UpdateAnnouncement;
