import { from } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { RetryLink } from "@apollo/client/link/retry";
import { createUploadLink } from "apollo-upload-client";
import { getFirebaseIdToken } from "../../firebase";

const uploadLink = createUploadLink({
  uri: `${process.env.REACT_APP_API_URL}/graphql`,
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );
  } else if (networkError) {
    console.log(`[Network error]: ${networkError}`);
  }
});

const retryLink = new RetryLink({
  delay: {
    initial: 300,
    max: Infinity,
    jitter: true,
  },
  attempts: {
    max: 5,
    retryIf: (error, operation) => !!error,
  },
});

const authLink = setContext(async (_, { headers }) => {
  try {
    const token = await getFirebaseIdToken();
    return { headers: { ...headers, authorization: token } };
  } catch (err) {
    console.error(err);
  }
});

export default from([retryLink, errorLink, authLink, uploadLink]);
