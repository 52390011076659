import React from "react";
import useTabs from "../../common/hooks/useTabs";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import PolicyList from "../components/PolicyList";
import { PolicyType } from "../../../lib/apollo/graphql/generated";

function PolicyContainer() {
  const {
    models: { tabIndex },
    operations: { onTabSelect },
  } = useTabs();

  return (
    <div>
      <Tabs tabIndex={tabIndex} onSelect={onTabSelect}>
        <TabList>
          <Tab>이용약관</Tab>
          <Tab>개인정보처리방침</Tab>
        </TabList>
        <TabPanel>
          <PolicyList type={PolicyType.Terms} />
        </TabPanel>
        <TabPanel>
          <PolicyList type={PolicyType.Privacy} />
        </TabPanel>
      </Tabs>
    </div>
  );
}

export default PolicyContainer;
