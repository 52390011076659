import React from "react";
import Flex from "../../common/components/Flex";

function HomeContainer() {
  return (
    <Flex>
      <div>hihi</div>
    </Flex>
  );
}

export default HomeContainer;
