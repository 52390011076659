import React, { useEffect, useState } from "react";
import imageCompression from "browser-image-compression";
import Select from "react-select/async";

import {
  AdvertiseInput,
  GetAllAdvertisesAsAdminDocument,
  useSearchAdCompaniesLazyQuery,
  useUpdateAdvertiseAsAdminMutation,
} from "../../../lib/apollo/graphql/generated";
import Flex from "../../common/components/Flex";
import theme from "../../../lib/theme";
import Input from "../../common/components/Input";
import Textarea from "../../common/components/Textarea";
import dayjs from "dayjs";
import { IMAGE_COMPRESSION_OPTIONS } from "../../../utils/constants";
import { uploadToFirebaseStorage } from "../../../lib/firebase";
import Button from "../../common/components/Button";
import { GroupBase, OptionsOrGroups } from "react-select";
import InputLabel from "../../common/components/InputLabel";

interface AdCardProps {
  ad?: {
    __typename?: "Advertise";
    id: string;
    imageUrl: string;
    url: string;
    title?: string | null;
    description?: string | null;
    from?: any | null;
    to?: any | null;
    createdAt: any;
    updatedAt: any;
    company?: { __typename?: "AdCompany"; id: string; name: string } | null;
  } | null;
  onCancel?: () => void;
}

function AdCard({ ad, onCancel }: AdCardProps) {
  const [state, setState] = useState({
    id: ad?.id || "",
    imageUrl: ad?.imageUrl || "",
    company: ad?.company
      ? { label: ad?.company?.name, value: ad?.company?.id }
      : null,
    url: ad?.url || "",
    title: ad?.title || "",
    description: ad?.description || "",
    from: dayjs(ad?.from).format("YYYY-MM-DD"),
    to: dayjs(ad?.to).format("YYYY-MM-DD"),
  });

  const [mediaState, setMediaState] = useState<File | null>(null);

  const [updateAdvertiseAsAdmin, { loading }] =
    useUpdateAdvertiseAsAdminMutation({
      onCompleted: (data) => {
        if (data?.updateAdvertiseAsAdmin) {
          setState({
            id: ad?.id || "",
            imageUrl: ad?.imageUrl || "",
            company: ad?.company
              ? { label: ad?.company?.name, value: ad?.company?.id }
              : null,
            url: ad?.url || "",
            title: ad?.title || "",
            description: ad?.description || "",
            from: dayjs(ad?.from).format("YYYY-MM-DD"),
            to: dayjs(ad?.to).format("YYYY-MM-DD"),
          });
          onCancel && onCancel();
        }
      },

      refetchQueries: !ad
        ? () => [
            {
              query: GetAllAdvertisesAsAdminDocument,
              variables: {
                first: 10,
              },
            },
          ]
        : undefined,
    });

  const [searchAdCompanies] = useSearchAdCompaniesLazyQuery();

  useEffect(() => {
    if (ad) {
      setState({
        id: ad?.id || "",
        imageUrl: ad?.imageUrl || "",
        company: ad?.company
          ? { label: ad?.company?.name, value: ad?.company?.id }
          : null,
        url: ad?.url || "",
        title: ad?.title || "",
        description: ad?.description || "",
        from: dayjs(ad?.from).format("YYYY-MM-DD"),
        to: dayjs(ad?.to).format("YYYY-MM-DD"),
      });
    }
  }, [ad]);

  function onImageChange(e: React.ChangeEvent<HTMLInputElement>) {
    const file = e.target.files?.[0];
    if (file) {
      setMediaState(file);
    }
  }

  function onInputChange(
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    key: string
  ) {
    const { value } = e.target;
    setState((prev) => {
      return {
        ...prev,
        [key]: value,
      };
    });
  }

  function loadOptions(
    inputValue: string
  ): Promise<OptionsOrGroups<unknown, GroupBase<unknown>>> {
    return new Promise((resolve) => {
      setTimeout(async () => {
        const { data } = await searchAdCompanies({
          variables: {
            query: inputValue,
            first: 100,
          },
        });

        const options = data?.searchAdCompanies?.edges.map(
          (company) =>
            company && {
              label: company.name,
              value: company.id,
            }
        );

        resolve(options!);
      }, 1);
    });
  }

  function onSelectorChange(value: any) {
    setState((prev) => ({
      ...prev,
      company: value,
    }));
  }

  async function onSubmit() {
    const { id, company, imageUrl, url, title, description, from, to } = state;

    let image = imageUrl;

    if (mediaState) {
      const compressedFile = await imageCompression(
        mediaState,
        IMAGE_COMPRESSION_OPTIONS
      );
      const { name, size, type } = compressedFile;

      const metadata = { name, size, type };
      const url = await uploadToFirebaseStorage(
        compressedFile,
        metadata,
        "public"
      );

      image = url;
    }

    if (image) {
      const advertiseInput: AdvertiseInput = {
        id,
        companyId: company?.value,
        imageUrl: image,
        url,
        title,
        description,
        from,
        to,
      };

      updateAdvertiseAsAdmin({
        variables: {
          advertiseInput,
        },
      });
    }
  }

  const { id, company, imageUrl, url, title, description, from, to } = state;

  const disabled = ((!mediaState || !imageUrl) && !url) || loading;

  return (
    <Flex
      flexDirection="column"
      width="100%"
      gap={theme.spacing[12]}
      borderRadius={12}
      border={`1px solid ${theme.color.gray3}`}
      padding={theme.spacing[12]}
    >
      <Input label="ID" value={id} disabled />
      <Flex flexDirection="column" gap={theme.spacing[4]}>
        <Input
          label="Image"
          type="file"
          accept="image/*"
          onChange={onImageChange}
        />
        {(mediaState || imageUrl) && (
          <img
            src={mediaState ? URL.createObjectURL(mediaState) : imageUrl}
            style={{ width: "50%", aspectRatio: 4 / 3, objectFit: "cover" }}
          />
        )}
      </Flex>
      <Input
        label="URL"
        value={url}
        onChange={(e) => onInputChange(e, "url")}
      />
      <Flex flexDirection="column" gap={theme.spacing[4]}>
        <InputLabel label="광고 회사" />
        <Select
          loadOptions={loadOptions}
          value={company}
          onChange={onSelectorChange}
        />
      </Flex>
      <Input
        label="Title"
        value={title}
        onChange={(e) => onInputChange(e, "title")}
      />
      <Textarea
        label="Description"
        value={description}
        onChange={(e) => onInputChange(e, "description")}
      />
      <Flex
        alignItems="center"
        justifyContent="space-between"
        gap={theme.spacing[8]}
      >
        <Input
          type="date"
          label="From"
          value={from}
          onChange={(e) => onInputChange(e, "from")}
        />
        <Input
          type="date"
          label="To"
          value={to}
          onChange={(e) => onInputChange(e, "to")}
        />
      </Flex>
      <Button text="제출" disabled={disabled} onClick={onSubmit} />
    </Flex>
  );
}

export default AdCard;
