import React from "react";
import Flex from "../../common/components/Flex";
import Button from "../../common/components/Button";
import useAnnouncementList from "./AnnouncementList.hook";
import Loader from "../../common/components/Loader";
import Table from "../../common/components/Table";
import TableCell from "../../common/components/TableCell";
import dayjs from "dayjs";
import FetchMore from "../../common/components/FetchMore";

function AnnouncementList() {
  const {
    refs: { fetchMoreRef },
    models: { loading, data },
    operations: { onEditClick },
  } = useAnnouncementList();

  if (loading) return <Loader />;

  return (
    <div>
      <Flex justifyContent="flex-end">
        <Button text="추가하기" onClick={() => onEditClick(null)} />
      </Flex>
      <Table
        columns={[
          {
            label: "ID",
          },
          {
            label: "Title",
          },
          {
            label: "Created At",
          },
          {
            label: "Updated At",
          },
          { label: "Edit" },
        ]}
        body={
          <>
            {data?.map((announcement) => (
              <tr key={announcement?.id}>
                <TableCell>{announcement?.id}</TableCell>
                <TableCell>{announcement?.title}</TableCell>
                <TableCell>
                  {dayjs(announcement?.createdAt).format("YYYY-MM-DD")}
                </TableCell>
                <TableCell>
                  {dayjs(announcement?.updatedAt).format("YYYY-MM-DD")}
                </TableCell>
                <TableCell>
                  <Button
                    text="Edit"
                    onClick={() => onEditClick(announcement?.id!)}
                  />
                </TableCell>
              </tr>
            ))}
          </>
        }
      />
      <FetchMore fetchMoreRef={fetchMoreRef} />
    </div>
  );
}

export default AnnouncementList;
