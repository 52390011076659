import { Outlet, createBrowserRouter } from "react-router-dom";
import FirebaseProvider from "../../../modules/common/providers/FirebaseProvider";
import HomeContainer from "../../../modules/home/container/HomeContainer";
import GiftOptionsContainer from "../../../modules/giftOptions/container/GiftOptionsContainer";
import PostContainer from "../../../modules/post/container/PostContainer";
import AdsContainer from "../../../modules/ads/container/AdsContainer";
import AnnouncementContainer from "../../../modules/announcement/container/AnnouncementContainer";
import UpdateAnnouncement from "../../../modules/announcement/components/UpdateAnnouncement";
import PolicyContainer from "../../../modules/policy/container/PolicyContainer";
import UpdatePolicy from "../../../modules/policy/components/UpdatePolicy";
import ManagementContainer from "../../../modules/management/container/ManagementContainer";
import UsersContainer from "../../../modules/users/users/container/UsersContainer";

export const SCREENS = {
  LANDING: "/",

  USERS: "/users",

  GIFT_OPTIONS: "/gift_options",

  POST: "/post",

  ADS: "/ads",

  ANNOUNCEMENT: "/announcement",

  POLICY: "/policy",

  MANAGEMENT: "/management",
};

export const SIDE_MENU = {
  [SCREENS.LANDING]: {
    name: "HOME",
  },

  [SCREENS.USERS]: {
    name: "유저 관리",
  },

  [SCREENS.GIFT_OPTIONS]: {
    name: "선물 옵션 관리",
  },

  [SCREENS.POST]: {
    name: "포스트 관리",
  },

  [SCREENS.ADS]: {
    name: "광고 관리",
  },

  [SCREENS.ANNOUNCEMENT]: {
    name: "공지 관리",
  },

  [SCREENS.POLICY]: {
    name: "약관 관리",
  },

  [SCREENS.MANAGEMENT]: {
    name: "회사 정보 관리",
  },
};

export const router = createBrowserRouter([
  {
    element: <FirebaseProvider />,
    children: [
      {
        path: SCREENS.LANDING,
        element: <HomeContainer />,
      },

      {
        path: SCREENS.USERS,
        element: <UsersContainer />,
      },

      {
        path: SCREENS.GIFT_OPTIONS,
        element: <GiftOptionsContainer />,
      },

      {
        path: SCREENS.POST,
        element: <PostContainer />,
      },

      {
        path: SCREENS.ADS,
        element: <AdsContainer />,
      },

      {
        path: SCREENS.ANNOUNCEMENT,
        element: <AnnouncementContainer />,
      },

      {
        path: SCREENS.ANNOUNCEMENT + "/" + ":announcementId",
        element: <UpdateAnnouncement />,
      },

      {
        path: SCREENS.POLICY,
        element: <PolicyContainer />,
      },

      {
        path: SCREENS.POLICY + "/" + ":type",
        element: <UpdatePolicy />,
      },

      {
        path: SCREENS.MANAGEMENT,
        element: <ManagementContainer />,
      },
    ],
  },
]);
