import React, { useState } from "react";
import { SingleValue } from "react-select";
import Select from "react-select";
import {
  GetPostsDocument,
  useAddPostAsAdminMutation,
  useGetPostCategoriesQuery,
  useGetPostFromsQuery,
} from "../../../lib/apollo/graphql/generated";
import Flex from "../../common/components/Flex";
import theme from "../../../lib/theme";
import Input from "../../common/components/Input";
import Button from "../../common/components/Button";

type State = {
  postCategory: SingleValue<{
    label: string;
    value: string;
  }> | null;
  postFrom: SingleValue<{
    label: string;
    value: string;
  }> | null;
  url: string;
  summary: string;
};

function AddPost() {
  const [state, setState] = useState<State>({
    postCategory: null,
    postFrom: null,
    url: "",
    summary: "",
  });

  const { loading, data } = useGetPostCategoriesQuery();
  const { loading: fromLoading, data: fromData } = useGetPostFromsQuery();

  const [addPostAsAdmin, { loading: updateLoading }] =
    useAddPostAsAdminMutation({
      onCompleted: (data) => {
        if (data?.addPostAsAdmin) {
          setState({
            postCategory: null,
            postFrom: null,
            url: "",
            summary: "",
          });
        }
      },
      refetchQueries: () => [
        {
          query: GetPostsDocument,
          variables: {
            postCategoryId: null,
            postFromId: null,
            first: 20,
          },
        },
      ],
    });

  function onInputChange(e: React.ChangeEvent<HTMLInputElement>, key: string) {
    const { value } = e.target;
    setState((prev) => {
      return {
        ...prev,
        [key]: value,
      };
    });
  }

  function onPickerChange(
    newValue: SingleValue<{
      label: string | undefined;
      value: string | undefined;
    }>,
    key: string
  ) {
    setState((prev) => ({
      ...prev,
      [key]: newValue,
    }));
  }

  function onSubmit() {
    const { postCategory, postFrom, url, summary } = state;

    addPostAsAdmin({
      variables: {
        postInput: {
          postCategoryId: postCategory!.value,
          postFromId: postFrom!.value,
          url,
          summary,
        },
      },
    });
  }

  return (
    <Flex
      alignItems="center"
      gap={theme.spacing[4]}
      marginBottom={theme.spacing[16]}
    >
      <Flex alignItems="center" gap={theme.spacing[4]}>
        <Select
          placeholder="카테고리"
          options={data?.getPostCategories?.map((item) => ({
            label: item?.names?.[0]?.name!,
            value: item?.id!,
          }))}
          value={state?.postCategory}
          onChange={(newValue) => onPickerChange(newValue, "postCategory")}
        />

        <Select
          placeholder="출처"
          options={fromData?.getPostFroms?.map((item) => ({
            label: item?.name!,
            value: item?.id!,
          }))}
          value={state?.postFrom}
          onChange={(newValue) => onPickerChange(newValue, "postFrom")}
        />
      </Flex>
      <Flex flex={1}>
        <Input
          placeholder="URL"
          value={state?.url}
          onChange={(e) => onInputChange(e, "url")}
        />
      </Flex>
      <Flex flex={1}>
        <Input
          placeholder="summary"
          value={state?.summary}
          onChange={(e) => onInputChange(e, "summary")}
        />
      </Flex>
      <Button
        text="등록"
        disabled={
          !state?.postCategory ||
          !state?.postFrom ||
          !state?.url ||
          updateLoading
        }
        onClick={onSubmit}
      />
    </Flex>
  );
}

export default AddPost;
