import React from "react";
import useAdsCompanies from "./AdsCompanies.hook";
import Loader from "../../common/components/Loader";
import Table from "../../common/components/Table";
import TableCell from "../../common/components/TableCell";
import FetchMore from "../../common/components/FetchMore";
import AdsCompanyRow from "./AdsCompanyRow";
import Flex from "../../common/components/Flex";
import Button from "../../common/components/Button";

function AdsCompanies() {
  const {
    refs: { fetchMoreRef },
    models: { loading, data, showNewRow },
    operations: { toggleShowNewRow },
  } = useAdsCompanies();

  if (loading) return <Loader />;

  return (
    <div style={{ width: "100%", position: "relative" }}>
      <Flex justifyContent="flex-end">
        <Button text="추가하기" onClick={() => toggleShowNewRow(true)} />
      </Flex>
      <Table
        columns={[
          {
            label: "ID",
            width: "5%",
          },
          {
            label: "NAME",
          },
          {
            label: "CEO",
          },
          {
            label: "REGISTRATION NUMBER",
          },
          {
            label: "WEBSITE",
          },
          {
            label: "PHONE",
          },
          {
            label: "MANAGER",
          },
          {
            label: "MANAGER PHONE",
          },
          {
            label: "ADS",
            width: "3%",
          },
          {
            label: "CREATED AT",
            width: "6%",
          },
          {
            label: "EDIT",
            width: "8%",
          },
        ]}
        body={
          <>
            {showNewRow && (
              <AdsCompanyRow
                company={null}
                onCancel={() => toggleShowNewRow(false)}
              />
            )}
            {data?.map((company) => (
              <AdsCompanyRow key={company?.id} company={company} />
            ))}
          </>
        }
      />
      <FetchMore fetchMoreRef={fetchMoreRef} />
    </div>
  );
}

export default AdsCompanies;
