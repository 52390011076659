import { useInView } from "react-intersection-observer";
import { useGetPostsQuery } from "../../../lib/apollo/graphql/generated";
import { useState } from "react";

function usePostList() {
  const { ref: fetchMoreRef } = useInView({
    threshold: 0.2,
    onChange: onEndReached,
  });

  const [fetchingMore, setFetchingMore] = useState(false);

  const { loading, data, fetchMore } = useGetPostsQuery({
    variables: {
      postCategoryId: null,
      postFromId: null,
      first: 20,
    },
  });

  function onEndReached(inView: boolean) {
    if (inView && !fetchingMore && data?.getPosts?.pageInfo?.hasNextPage) {
      setFetchingMore(true);

      fetchMore({
        variables: {
          first: 20,
          after: data?.getPosts?.pageInfo?.endCursor,
        },
      }).finally(() => {
        setFetchingMore(false);
      });
    }
  }

  return {
    refs: {
      fetchMoreRef,
    },
    models: {
      loading,
      data: data?.getPosts?.edges,
    },
  };
}

export default usePostList;
