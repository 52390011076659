import React from "react";
import usePostFroms from "./PostFroms.hook";
import Loader from "../../common/components/Loader";
import Table from "../../common/components/Table";
import PostFromRow from "./PostFromRow";

function PostFroms() {
  const {
    models: { loading, data },
  } = usePostFroms();

  if (loading) return <Loader />;

  return (
    <div>
      <Table
        columns={[
          { label: "ID" },
          { label: "NAME" },
          { label: "THUMBNAIL" },
          { label: "Action" },
        ]}
        body={
          <>
            <PostFromRow item={null} />
            {data?.map((item) => (
              <PostFromRow
                key={item?.id}
                item={{
                  id: item?.id,
                  name: item?.name,
                  thumbnail: item?.thumbnail?.uri,
                }}
              />
            ))}
          </>
        }
      />
    </div>
  );
}

export default PostFroms;
