import React from "react";
import useTabs from "../../common/hooks/useTabs";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import PostList from "../components/PostList";
import PostCategories from "../components/PostCategories";
import PostFroms from "../components/PostFroms";

function PostContainer() {
  const {
    models: { tabIndex },
    operations: { onTabSelect },
  } = useTabs();

  return (
    <div>
      <Tabs tabIndex={tabIndex} onSelect={onTabSelect}>
        <TabList>
          <Tab>포스트</Tab>
          <Tab>포스트 카테고리</Tab>
          <Tab>포스트 출처</Tab>
        </TabList>
        <TabPanel>
          <PostList />
        </TabPanel>
        <TabPanel>
          <PostCategories />
        </TabPanel>
        <TabPanel>
          <PostFroms />
        </TabPanel>
      </Tabs>
    </div>
  );
}

export default PostContainer;
