import React, { useState } from "react";
import {
  useGetManagementQuery,
  useUpdateManagementAsAdminMutation,
} from "../../../lib/apollo/graphql/generated";
import Flex from "../../common/components/Flex";
import Button from "../../common/components/Button";
import Input from "../../common/components/Input";
import Grid from "../../common/components/Grid";
import theme from "../../../lib/theme";

const LABEL_MAP = {
  companyName: "회사이름",
  address: "주소",
  copyright: "Copyright",
  email: "이메일",
  ceoName: "대표",
  registrationNumber: "사업자등록번호",
  escroRegistration: "통신판매업 신고번호",
  phone: "전화번호",
  kakaoAddress: "카카오톡 주소",
  shopAddress: "샵 주소",
  instagramAddress: "인스타그램 주소",
};

function ManagementContainer() {
  const [state, setState] = useState({
    companyName: "",
    address: "",
    copyright: "",
    email: "",
    ceoName: "",
    registrationNumber: "",
    escroRegistration: "",
    phone: "",
    kakaoAddress: "",
    shopAddress: "",
    instagramAddress: "",
  });

  const { loading, data } = useGetManagementQuery({
    onCompleted: (data) => {
      if (data?.getManagement) {
        setState({
          companyName: data?.getManagement?.companyName || "",
          address: data?.getManagement?.address || "",
          copyright: data?.getManagement?.copyright || "",
          email: data?.getManagement?.email || "",
          ceoName: data?.getManagement?.ceoName || "",
          registrationNumber: data?.getManagement?.registrationNumber || "",
          escroRegistration: data?.getManagement?.escroRegistration || "",
          phone: data?.getManagement?.phone || "",
          kakaoAddress: data?.getManagement?.kakaoAddress || "",
          shopAddress: data?.getManagement?.shopAddress || "",
          instagramAddress: data?.getManagement?.instagramAddress || "",
        });
      }
    },
  });

  const [updateManagementAsAdmin, { loading: updateLoading }] =
    useUpdateManagementAsAdminMutation();

  function onInputChange(e: React.ChangeEvent<HTMLInputElement>, key: string) {
    const { value } = e.target;

    setState((prev) => ({
      ...prev,
      [key]: value,
    }));
  }

  function onSubmit() {
    updateManagementAsAdmin({
      variables: {
        managementInput: {
          ...state,
        },
      },
    });
  }

  return (
    <div>
      <Flex justifyContent="flex-end">
        <Button disabled={updateLoading} text="Submit" onClick={onSubmit} />
      </Flex>
      <Grid gridTemplateColumns="repeat(2, 1fr)" gap={theme.spacing[12]}>
        {Object.keys(state)?.map((key) => {
          const value = state[key as keyof typeof state];
          const label = LABEL_MAP[key as keyof typeof LABEL_MAP];

          return (
            <Input
              key={key}
              label={label}
              value={value}
              placeholder={key}
              onChange={(e) => onInputChange(e, key)}
            />
          );
        })}
      </Grid>
    </div>
  );
}

export default ManagementContainer;
