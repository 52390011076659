import { useState } from "react";
import { useInView } from "react-intersection-observer";
import { useGetAnnouncementsQuery } from "../../../lib/apollo/graphql/generated";
import { useNavigate } from "react-router-dom";
import { SCREENS } from "../../../lib/route/utils/router";

function useAnnouncementList() {
  const navigate = useNavigate();

  const { ref: fetchMoreRef } = useInView({
    threshold: 0.2,
    onChange: onEndReached,
  });

  const [fetchingMore, setFetchingMore] = useState(false);

  const { loading, data, fetchMore } = useGetAnnouncementsQuery({
    variables: {
      activeOnly: false,
      first: 20,
    },
  });

  function onEndReached(inView: boolean) {
    if (
      inView &&
      !fetchingMore &&
      data?.getAnnouncements?.pageInfo?.hasNextPage
    ) {
      setFetchingMore(true);

      fetchMore({
        variables: {
          first: 20,
          after: data?.getAnnouncements?.pageInfo?.endCursor,
        },
      }).finally(() => {
        setFetchingMore(false);
      });
    }
  }

  function onEditClick(announcementId: string | null) {
    navigate(SCREENS.ANNOUNCEMENT + "/" + announcementId);
  }

  return {
    refs: {
      fetchMoreRef,
    },
    models: {
      loading,
      data: data?.getAnnouncements?.edges,
    },
    operations: {
      onEditClick,
    },
  };
}

export default useAnnouncementList;
