import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Date: { input: any; output: any; }
  DateTime: { input: any; output: any; }
  PhoneNumber: { input: any; output: any; }
  Upload: { input: any; output: any; }
};

export type AdCompany = {
  __typename?: 'AdCompany';
  ads?: Maybe<Array<Maybe<Advertise>>>;
  adsCount: Scalars['Int']['output'];
  ceo: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  manager?: Maybe<Scalars['String']['output']>;
  managerPhone?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  registrationNumber?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  url?: Maybe<Scalars['String']['output']>;
};

export type AdCompanyConnection = {
  __typename?: 'AdCompanyConnection';
  edges: Array<Maybe<AdCompany>>;
  pageInfo: PageInfo;
};

export type AdCompanyInput = {
  ceo: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  manager?: InputMaybe<Scalars['String']['input']>;
  managerPhone?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  registrationNumber?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type Advertise = {
  __typename?: 'Advertise';
  company?: Maybe<AdCompany>;
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  from?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  imageUrl: Scalars['String']['output'];
  title?: Maybe<Scalars['String']['output']>;
  to?: Maybe<Scalars['DateTime']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  url: Scalars['String']['output'];
};

export type AdvertiseConnection = {
  __typename?: 'AdvertiseConnection';
  edges: Array<Maybe<Advertise>>;
  pageInfo: PageInfo;
};

export type AdvertiseInput = {
  companyId?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  from?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  imageUrl: Scalars['String']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
  to?: InputMaybe<Scalars['DateTime']['input']>;
  url: Scalars['String']['input'];
};

export type Announcement = {
  __typename?: 'Announcement';
  active: Scalars['Boolean']['output'];
  body: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  title: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type AnnouncementConnection = {
  __typename?: 'AnnouncementConnection';
  edges: Array<Maybe<Announcement>>;
  pageInfo: PageInfo;
};

export type AnnouncementInput = {
  active: Scalars['Boolean']['input'];
  body: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  title: Scalars['String']['input'];
};

export type Category = {
  __typename?: 'Category';
  active: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  names?: Maybe<Array<Maybe<CategoryName>>>;
  order: Scalars['Int']['output'];
};

export type CategoryInput = {
  active: Scalars['Boolean']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  order: Scalars['Int']['input'];
};

export type CategoryName = {
  __typename?: 'CategoryName';
  category: Category;
  id: Scalars['ID']['output'];
  languageCode: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type CategoryNameInput = {
  categoryId: Scalars['ID']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  languageCode: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type File = {
  __typename?: 'File';
  encoding: Scalars['String']['output'];
  filename: Scalars['String']['output'];
  mimetype: Scalars['String']['output'];
};

export type Friend = {
  __typename?: 'Friend';
  company?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  emails?: Maybe<Array<Maybe<FriendEmail>>>;
  familyName?: Maybe<Scalars['String']['output']>;
  givenName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  jobTitle?: Maybe<Scalars['String']['output']>;
  middleName?: Maybe<Scalars['String']['output']>;
  phones?: Maybe<Array<Maybe<FriendPhone>>>;
  recordId?: Maybe<Scalars['String']['output']>;
  thumbnail?: Maybe<Media>;
  thumbnailPath?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  user: User;
};

export type FriendConnection = {
  __typename?: 'FriendConnection';
  edges: Array<Maybe<Friend>>;
  pageInfo: PageInfo;
};

export type FriendEmail = {
  __typename?: 'FriendEmail';
  email: Scalars['String']['output'];
  friend: Friend;
  id: Scalars['ID']['output'];
  label?: Maybe<Scalars['String']['output']>;
};

export type FriendEmailInput = {
  email: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
};

export type FriendInput = {
  company?: InputMaybe<Scalars['String']['input']>;
  emailInputs?: InputMaybe<Array<InputMaybe<FriendEmailInput>>>;
  familyName?: InputMaybe<Scalars['String']['input']>;
  givenName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  middleName?: InputMaybe<Scalars['String']['input']>;
  phoneInputs?: InputMaybe<Array<InputMaybe<FriendPhoneInput>>>;
  recordId?: InputMaybe<Scalars['String']['input']>;
  thumbnailInput?: InputMaybe<MediaInput>;
  thumbnailPath?: InputMaybe<Scalars['String']['input']>;
};

export type FriendPhone = {
  __typename?: 'FriendPhone';
  friend: Friend;
  id: Scalars['ID']['output'];
  label?: Maybe<Scalars['String']['output']>;
  phone: Scalars['String']['output'];
};

export type FriendPhoneInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  phone: Scalars['String']['input'];
};

export enum Gender {
  Female = 'FEMALE',
  Male = 'MALE'
}

export type Gift = {
  __typename?: 'Gift';
  category?: Maybe<Category>;
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  friend?: Maybe<Friend>;
  id: Scalars['ID']['output'];
  isReceived: Scalars['Boolean']['output'];
  media?: Maybe<Media>;
  name: Scalars['String']['output'];
  price: Scalars['Float']['output'];
  purpose?: Maybe<Purpose>;
  receivedAt: Scalars['Date']['output'];
  updatedAt: Scalars['DateTime']['output'];
  user: User;
};

export type GiftConnection = {
  __typename?: 'GiftConnection';
  edges: Array<Maybe<Gift>>;
  pageInfo: PageInfo;
};

export type GiftInput = {
  categoryId?: InputMaybe<Scalars['ID']['input']>;
  friendId?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  isReceived: Scalars['Boolean']['input'];
  mediaInput?: InputMaybe<MediaInput>;
  name: Scalars['String']['input'];
  price: Scalars['Float']['input'];
  purposeId?: InputMaybe<Scalars['ID']['input']>;
  receivedAt: Scalars['Date']['input'];
};

export type Home = {
  __typename?: 'Home';
  amounts: HomeAmount;
  gifts: HomeGift;
  purposes: HomePurpose;
};

export type HomeAmount = {
  __typename?: 'HomeAmount';
  expense?: Maybe<Scalars['Float']['output']>;
  income?: Maybe<Scalars['Float']['output']>;
};

export type HomeGift = {
  __typename?: 'HomeGift';
  expense?: Maybe<Gift>;
  income?: Maybe<Gift>;
};

export type HomePurpose = {
  __typename?: 'HomePurpose';
  expense?: Maybe<Purpose>;
  income?: Maybe<Purpose>;
};

export type Management = {
  __typename?: 'Management';
  address?: Maybe<Scalars['String']['output']>;
  ceoName?: Maybe<Scalars['String']['output']>;
  companyName?: Maybe<Scalars['String']['output']>;
  copyright?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  escroRegistration?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  instagramAddress?: Maybe<Scalars['String']['output']>;
  kakaoAddress?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  registrationNumber?: Maybe<Scalars['String']['output']>;
  shopAddress?: Maybe<Scalars['String']['output']>;
};

export type ManagementInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  ceoName?: InputMaybe<Scalars['String']['input']>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  copyright?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  escroRegistration?: InputMaybe<Scalars['String']['input']>;
  instagramAddress?: InputMaybe<Scalars['String']['input']>;
  kakaoAddress?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  registrationNumber?: InputMaybe<Scalars['String']['input']>;
  shopAddress?: InputMaybe<Scalars['String']['input']>;
};

export type Media = {
  __typename?: 'Media';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  size: Scalars['Int']['output'];
  /** This is provided only when thumbnailFile is uploaded */
  thumbnailUri?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
  uri: Scalars['String']['output'];
};

export type MediaInput = {
  file: Scalars['Upload']['input'];
  thumbnailFile?: InputMaybe<Scalars['Upload']['input']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  _empty?: Maybe<Scalars['Boolean']['output']>;
  addFriends: FriendConnection;
  addPostAsAdmin: Post;
  deleteMyFriend: Scalars['Boolean']['output'];
  deleteMyGift: Scalars['Boolean']['output'];
  deletePostAsAdmin: Scalars['Boolean']['output'];
  resign: Scalars['Boolean']['output'];
  updateAdCompanyAsAdmin: AdCompany;
  updateAdvertiseAsAdmin: Advertise;
  updateAnnouncementAsAdmin: Announcement;
  updateCategoryAsAdmin: Category;
  updateCategoryNameAsAdmin: CategoryName;
  updateManagementAsAdmin: Management;
  updateMyFriend: Friend;
  updateMyGift: Gift;
  updateMyUser: User;
  updateNoticeAsAdmin: Notice;
  updatePolicyAsAdmin: Policy;
  updatePostCategoryAsAdmin: PostCategory;
  updatePostCategoryNameAsAdmin: PostCategoryName;
  updatePostFromAsAdmin: PostFrom;
  updatePurposeAsAdmin: Purpose;
  updatePurposeNameAsAdmin: PurposeName;
};


export type MutationAddFriendsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  friendInputs: Array<InputMaybe<FriendInput>>;
};


export type MutationAddPostAsAdminArgs = {
  postInput: PostInput;
};


export type MutationDeleteMyFriendArgs = {
  friendId: Scalars['ID']['input'];
};


export type MutationDeleteMyGiftArgs = {
  giftId: Scalars['ID']['input'];
};


export type MutationDeletePostAsAdminArgs = {
  postId: Scalars['ID']['input'];
};


export type MutationResignArgs = {
  reason?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateAdCompanyAsAdminArgs = {
  adCompanyInput: AdCompanyInput;
};


export type MutationUpdateAdvertiseAsAdminArgs = {
  advertiseInput: AdvertiseInput;
};


export type MutationUpdateAnnouncementAsAdminArgs = {
  announcementInput: AnnouncementInput;
};


export type MutationUpdateCategoryAsAdminArgs = {
  categoryInput: CategoryInput;
};


export type MutationUpdateCategoryNameAsAdminArgs = {
  categoryNameInput: CategoryNameInput;
};


export type MutationUpdateManagementAsAdminArgs = {
  managementInput: ManagementInput;
};


export type MutationUpdateMyFriendArgs = {
  friendInput: FriendInput;
};


export type MutationUpdateMyGiftArgs = {
  giftInput: GiftInput;
};


export type MutationUpdateMyUserArgs = {
  userInput: UserInput;
};


export type MutationUpdateNoticeAsAdminArgs = {
  noticeInput: NoticeInput;
};


export type MutationUpdatePolicyAsAdminArgs = {
  policyInput: PolicyInput;
};


export type MutationUpdatePostCategoryAsAdminArgs = {
  categoryInput: PostCategoryInput;
};


export type MutationUpdatePostCategoryNameAsAdminArgs = {
  categoryNameInput: PostCategoryNameInput;
};


export type MutationUpdatePostFromAsAdminArgs = {
  postFromInput: PostFromInput;
};


export type MutationUpdatePurposeAsAdminArgs = {
  purposeInput: PurposeInput;
};


export type MutationUpdatePurposeNameAsAdminArgs = {
  purposeNameInput: PurposeNameInput;
};

export type Notice = {
  __typename?: 'Notice';
  active: Scalars['Boolean']['output'];
  announcement?: Maybe<Announcement>;
  createdAt: Scalars['DateTime']['output'];
  externalUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type NoticeConnection = {
  __typename?: 'NoticeConnection';
  edges: Array<Maybe<Notice>>;
  pageInfo: PageInfo;
};

export type NoticeInput = {
  active: Scalars['Boolean']['input'];
  announcementId?: InputMaybe<Scalars['ID']['input']>;
  externalUrl?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
};

export type PaginationInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
};

export type Policy = {
  __typename?: 'Policy';
  body: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  type: PolicyType;
};

export type PolicyInput = {
  body: Scalars['String']['input'];
  type: PolicyType;
};

export enum PolicyType {
  Privacy = 'PRIVACY',
  Terms = 'TERMS'
}

export type Post = {
  __typename?: 'Post';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  postCategory: PostCategory;
  postFrom?: Maybe<PostFrom>;
  summary?: Maybe<Scalars['String']['output']>;
  thumbnailUri?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type PostCategory = {
  __typename?: 'PostCategory';
  active: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  names?: Maybe<Array<Maybe<PostCategoryName>>>;
  order: Scalars['Int']['output'];
};

export type PostCategoryInput = {
  active: Scalars['Boolean']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  order: Scalars['Int']['input'];
};

export type PostCategoryName = {
  __typename?: 'PostCategoryName';
  id: Scalars['ID']['output'];
  languageCode: Scalars['String']['output'];
  name: Scalars['String']['output'];
  postCategory: PostCategory;
};

export type PostCategoryNameInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  languageCode: Scalars['String']['input'];
  name: Scalars['String']['input'];
  postCategoryId: Scalars['ID']['input'];
};

export type PostConnection = {
  __typename?: 'PostConnection';
  edges: Array<Maybe<Post>>;
  pageInfo: PageInfo;
};

export type PostFrom = {
  __typename?: 'PostFrom';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  thumbnail?: Maybe<Media>;
};

export type PostFromInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  thumbnailInput?: InputMaybe<MediaInput>;
};

export type PostInput = {
  postCategoryId: Scalars['ID']['input'];
  postFromId?: InputMaybe<Scalars['ID']['input']>;
  summary?: InputMaybe<Scalars['String']['input']>;
  url: Scalars['String']['input'];
};

export type Purpose = {
  __typename?: 'Purpose';
  active: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  names?: Maybe<Array<Maybe<PurposeName>>>;
  order: Scalars['Int']['output'];
};

export type PurposeInput = {
  active: Scalars['Boolean']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  order: Scalars['Int']['input'];
};

export type PurposeName = {
  __typename?: 'PurposeName';
  id: Scalars['ID']['output'];
  languageCode: Scalars['String']['output'];
  name: Scalars['String']['output'];
  purpose: Purpose;
};

export type PurposeNameInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  languageCode: Scalars['String']['input'];
  name: Scalars['String']['input'];
  purposeId: Scalars['ID']['input'];
};

export type Query = {
  __typename?: 'Query';
  _empty?: Maybe<Scalars['Boolean']['output']>;
  checkIfEmailExist: Scalars['Boolean']['output'];
  getActiveAdvertise: Array<Maybe<Advertise>>;
  getActiveNotices: Array<Maybe<Notice>>;
  getAdCompanies: AdCompanyConnection;
  getAdCompany: AdCompany;
  getAdvertise: Advertise;
  getAllAdvertisesAsAdmin: AdvertiseConnection;
  getAllNoticesAsAdmin: NoticeConnection;
  getAnnouncement: Announcement;
  getAnnouncements: AnnouncementConnection;
  getCategories: Array<Maybe<Category>>;
  getFriend?: Maybe<Friend>;
  getGift: Gift;
  getHome: Home;
  getLatestPolicy: Policy;
  /** This query is for client application and admin both! */
  getManagement?: Maybe<Management>;
  getMyFriends: FriendConnection;
  getMyGifts: GiftConnection;
  getMyGiftsByDate: Array<Maybe<Gift>>;
  getMyUser?: Maybe<User>;
  getNotice: Notice;
  /**
   * This is used if you wanna show or see policy records by type
   * For now, it can be used in the admin console, but later you can use this in the client app
   */
  getPolicies: Array<Maybe<Policy>>;
  getPostCategories: Array<Maybe<PostCategory>>;
  getPostFrom: PostFrom;
  getPostFroms: Array<Maybe<PostFrom>>;
  getPosts: PostConnection;
  getPurposes: Array<Maybe<Purpose>>;
  getUser: User;
  getUserFriendsAsAdmin: FriendConnection;
  getUserGiftsAsAdmin: GiftConnection;
  getUserGiftsByDateAsAdmin: Array<Maybe<Gift>>;
  getUsersAsAdmin: UserConnection;
  searchAdCompanies: AdCompanyConnection;
  searchMyFriends: FriendConnection;
};


export type QueryCheckIfEmailExistArgs = {
  email: Scalars['String']['input'];
};


export type QueryGetAdCompaniesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetAdCompanyArgs = {
  adCompanyId: Scalars['ID']['input'];
};


export type QueryGetAdvertiseArgs = {
  advertiseId: Scalars['ID']['input'];
};


export type QueryGetAllAdvertisesAsAdminArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetAllNoticesAsAdminArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetAnnouncementArgs = {
  announcementId: Scalars['ID']['input'];
};


export type QueryGetAnnouncementsArgs = {
  activeOnly: Scalars['Boolean']['input'];
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetFriendArgs = {
  friendId: Scalars['ID']['input'];
};


export type QueryGetGiftArgs = {
  giftId: Scalars['ID']['input'];
};


export type QueryGetHomeArgs = {
  from: Scalars['DateTime']['input'];
  to: Scalars['DateTime']['input'];
};


export type QueryGetLatestPolicyArgs = {
  type: PolicyType;
};


export type QueryGetMyFriendsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetMyGiftsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetMyGiftsByDateArgs = {
  from: Scalars['DateTime']['input'];
  to: Scalars['DateTime']['input'];
};


export type QueryGetNoticeArgs = {
  noticeId: Scalars['ID']['input'];
};


export type QueryGetPoliciesArgs = {
  type: PolicyType;
};


export type QueryGetPostFromArgs = {
  postFromId: Scalars['ID']['input'];
};


export type QueryGetPostsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  postCategoryId?: InputMaybe<Scalars['ID']['input']>;
  postFromId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryGetUserArgs = {
  userId: Scalars['ID']['input'];
};


export type QueryGetUserFriendsAsAdminArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  userId: Scalars['ID']['input'];
};


export type QueryGetUserGiftsAsAdminArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  userId: Scalars['ID']['input'];
};


export type QueryGetUserGiftsByDateAsAdminArgs = {
  from?: InputMaybe<Scalars['Date']['input']>;
  to?: InputMaybe<Scalars['Date']['input']>;
  userId: Scalars['ID']['input'];
};


export type QueryGetUsersAsAdminArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
};


export type QuerySearchAdCompaniesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  query: Scalars['String']['input'];
};


export type QuerySearchMyFriendsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  query: Scalars['String']['input'];
};

export type User = {
  __typename?: 'User';
  avatar?: Maybe<Media>;
  createdAt: Scalars['DateTime']['output'];
  dateOfBirth?: Maybe<Scalars['Date']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  friends?: Maybe<Array<Maybe<Friend>>>;
  friendsCount: Scalars['Int']['output'];
  gender?: Maybe<Gender>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  phoneCode?: Maybe<Scalars['String']['output']>;
  provider?: Maybe<Scalars['String']['output']>;
  resignReason?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type UserConnection = {
  __typename?: 'UserConnection';
  edges: Array<Maybe<User>>;
  pageInfo: PageInfo;
};

export type UserInput = {
  avatarInput?: InputMaybe<MediaInput>;
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Gender>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  phoneCode?: InputMaybe<Scalars['String']['input']>;
};

export type GetAdCompaniesQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetAdCompaniesQuery = { __typename?: 'Query', getAdCompanies: { __typename?: 'AdCompanyConnection', pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, endCursor?: string | null }, edges: Array<{ __typename?: 'AdCompany', id: string, name: string, ceo: string, registrationNumber?: string | null, url?: string | null, phone?: string | null, manager?: string | null, managerPhone?: string | null, adsCount: number, createdAt: any, updatedAt: any } | null> } };

export type GetAdCompanyQueryVariables = Exact<{
  adCompanyId: Scalars['ID']['input'];
}>;


export type GetAdCompanyQuery = { __typename?: 'Query', getAdCompany: { __typename?: 'AdCompany', id: string, name: string, ceo: string, registrationNumber?: string | null, url?: string | null, phone?: string | null, manager?: string | null, managerPhone?: string | null, adsCount: number, createdAt: any, updatedAt: any } };

export type SearchAdCompaniesQueryVariables = Exact<{
  query: Scalars['String']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
}>;


export type SearchAdCompaniesQuery = { __typename?: 'Query', searchAdCompanies: { __typename?: 'AdCompanyConnection', pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, endCursor?: string | null }, edges: Array<{ __typename?: 'AdCompany', id: string, name: string, ceo: string, registrationNumber?: string | null, url?: string | null, phone?: string | null, manager?: string | null, managerPhone?: string | null, adsCount: number, createdAt: any, updatedAt: any } | null> } };

export type GetAllAdvertisesAsAdminQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetAllAdvertisesAsAdminQuery = { __typename?: 'Query', getAllAdvertisesAsAdmin: { __typename?: 'AdvertiseConnection', pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, endCursor?: string | null }, edges: Array<{ __typename?: 'Advertise', id: string, imageUrl: string, url: string, title?: string | null, description?: string | null, from?: any | null, to?: any | null, createdAt: any, updatedAt: any, company?: { __typename?: 'AdCompany', id: string, name: string } | null } | null> } };

export type GetAdvertiseQueryVariables = Exact<{
  advertiseId: Scalars['ID']['input'];
}>;


export type GetAdvertiseQuery = { __typename?: 'Query', getAdvertise: { __typename?: 'Advertise', id: string, imageUrl: string, url: string, title?: string | null, description?: string | null, from?: any | null, to?: any | null, createdAt: any, updatedAt: any, company?: { __typename?: 'AdCompany', id: string, name: string } | null } };

export type UpdateAdCompanyAsAdminMutationVariables = Exact<{
  adCompanyInput: AdCompanyInput;
}>;


export type UpdateAdCompanyAsAdminMutation = { __typename?: 'Mutation', updateAdCompanyAsAdmin: { __typename?: 'AdCompany', id: string, name: string, ceo: string, registrationNumber?: string | null, url?: string | null, phone?: string | null, manager?: string | null, managerPhone?: string | null, adsCount: number, createdAt: any, updatedAt: any } };

export type UpdateAdvertiseAsAdminMutationVariables = Exact<{
  advertiseInput: AdvertiseInput;
}>;


export type UpdateAdvertiseAsAdminMutation = { __typename?: 'Mutation', updateAdvertiseAsAdmin: { __typename?: 'Advertise', id: string, imageUrl: string, url: string, title?: string | null, description?: string | null, from?: any | null, to?: any | null, createdAt: any, updatedAt: any, company?: { __typename?: 'AdCompany', id: string, name: string } | null } };

export type GetAnnouncementsQueryVariables = Exact<{
  activeOnly: Scalars['Boolean']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetAnnouncementsQuery = { __typename?: 'Query', getAnnouncements: { __typename?: 'AnnouncementConnection', pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, endCursor?: string | null }, edges: Array<{ __typename?: 'Announcement', id: string, title: string, body: string, active: boolean, createdAt: any, updatedAt: any } | null> } };

export type GetAnnouncementQueryVariables = Exact<{
  announcementId: Scalars['ID']['input'];
}>;


export type GetAnnouncementQuery = { __typename?: 'Query', getAnnouncement: { __typename?: 'Announcement', id: string, title: string, body: string, active: boolean, createdAt: any, updatedAt: any } };

export type GetAllNoticesAsAdminQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetAllNoticesAsAdminQuery = { __typename?: 'Query', getAllNoticesAsAdmin: { __typename?: 'NoticeConnection', pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, endCursor?: string | null }, edges: Array<{ __typename?: 'Notice', id: string, imageUrl?: string | null, externalUrl?: string | null, active: boolean, createdAt: any, updatedAt: any, announcement?: { __typename?: 'Announcement', id: string, title: string } | null } | null> } };

export type UpdateAnnouncementAsAdminMutationVariables = Exact<{
  announcementInput: AnnouncementInput;
}>;


export type UpdateAnnouncementAsAdminMutation = { __typename?: 'Mutation', updateAnnouncementAsAdmin: { __typename?: 'Announcement', id: string, title: string, body: string, active: boolean, createdAt: any, updatedAt: any } };

export type UpdateNoticeAsAdminMutationVariables = Exact<{
  noticeInput: NoticeInput;
}>;


export type UpdateNoticeAsAdminMutation = { __typename?: 'Mutation', updateNoticeAsAdmin: { __typename?: 'Notice', id: string, imageUrl?: string | null, externalUrl?: string | null, active: boolean, createdAt: any, updatedAt: any, announcement?: { __typename?: 'Announcement', id: string, title: string } | null } };

export type GetManagementQueryVariables = Exact<{ [key: string]: never; }>;


export type GetManagementQuery = { __typename?: 'Query', getManagement?: { __typename?: 'Management', id: string, companyName?: string | null, address?: string | null, copyright?: string | null, email?: string | null, ceoName?: string | null, registrationNumber?: string | null, escroRegistration?: string | null, phone?: string | null, kakaoAddress?: string | null, shopAddress?: string | null, instagramAddress?: string | null } | null };

export type UpdateManagementAsAdminMutationVariables = Exact<{
  managementInput: ManagementInput;
}>;


export type UpdateManagementAsAdminMutation = { __typename?: 'Mutation', updateManagementAsAdmin: { __typename?: 'Management', id: string, companyName?: string | null, address?: string | null, copyright?: string | null, email?: string | null, ceoName?: string | null, registrationNumber?: string | null, escroRegistration?: string | null, phone?: string | null, kakaoAddress?: string | null, shopAddress?: string | null, instagramAddress?: string | null } };

export type GetCategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCategoriesQuery = { __typename?: 'Query', getCategories: Array<{ __typename?: 'Category', id: string, active: boolean, order: number, names?: Array<{ __typename?: 'CategoryName', id: string, languageCode: string, name: string } | null> | null } | null> };

export type GetPurposesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPurposesQuery = { __typename?: 'Query', getPurposes: Array<{ __typename?: 'Purpose', id: string, active: boolean, order: number, names?: Array<{ __typename?: 'PurposeName', id: string, languageCode: string, name: string } | null> | null } | null> };

export type UpdatePurposeAsAdminMutationVariables = Exact<{
  purposeInput: PurposeInput;
}>;


export type UpdatePurposeAsAdminMutation = { __typename?: 'Mutation', updatePurposeAsAdmin: { __typename?: 'Purpose', id: string, active: boolean, order: number, names?: Array<{ __typename?: 'PurposeName', id: string, languageCode: string, name: string } | null> | null } };

export type UpdatePurposeNameAsAdminMutationVariables = Exact<{
  purposeNameInput: PurposeNameInput;
}>;


export type UpdatePurposeNameAsAdminMutation = { __typename?: 'Mutation', updatePurposeNameAsAdmin: { __typename?: 'PurposeName', id: string, languageCode: string, name: string, purpose: { __typename?: 'Purpose', id: string } } };

export type UpdateCategoryAsAdminMutationVariables = Exact<{
  categoryInput: CategoryInput;
}>;


export type UpdateCategoryAsAdminMutation = { __typename?: 'Mutation', updateCategoryAsAdmin: { __typename?: 'Category', id: string, active: boolean, order: number, names?: Array<{ __typename?: 'CategoryName', id: string, languageCode: string, name: string } | null> | null } };

export type UpdateCategoryNameAsAdminMutationVariables = Exact<{
  categoryNameInput: CategoryNameInput;
}>;


export type UpdateCategoryNameAsAdminMutation = { __typename?: 'Mutation', updateCategoryNameAsAdmin: { __typename?: 'CategoryName', id: string, languageCode: string, name: string, category: { __typename?: 'Category', id: string } } };

export type GetPoliciesQueryVariables = Exact<{
  type: PolicyType;
}>;


export type GetPoliciesQuery = { __typename?: 'Query', getPolicies: Array<{ __typename?: 'Policy', id: string, type: PolicyType, body: string, createdAt: any } | null> };

export type GetLatestPolicyQueryVariables = Exact<{
  type: PolicyType;
}>;


export type GetLatestPolicyQuery = { __typename?: 'Query', getLatestPolicy: { __typename?: 'Policy', id: string, type: PolicyType, body: string, createdAt: any } };

export type UpdatePolicyAsAdminMutationVariables = Exact<{
  policyInput: PolicyInput;
}>;


export type UpdatePolicyAsAdminMutation = { __typename?: 'Mutation', updatePolicyAsAdmin: { __typename?: 'Policy', id: string, type: PolicyType, body: string, createdAt: any } };

export type GetPostsQueryVariables = Exact<{
  postCategoryId?: InputMaybe<Scalars['ID']['input']>;
  postFromId?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetPostsQuery = { __typename?: 'Query', getPosts: { __typename?: 'PostConnection', pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, endCursor?: string | null }, edges: Array<{ __typename?: 'Post', id: string, url: string, title: string, thumbnailUri?: string | null, summary?: string | null, createdAt: any, postCategory: { __typename?: 'PostCategory', id: string, names?: Array<{ __typename?: 'PostCategoryName', languageCode: string, name: string, id: string } | null> | null }, postFrom?: { __typename?: 'PostFrom', id: string, name: string, thumbnail?: { __typename?: 'Media', id: string, uri: string } | null } | null } | null> } };

export type AddPostAsAdminMutationVariables = Exact<{
  postInput: PostInput;
}>;


export type AddPostAsAdminMutation = { __typename?: 'Mutation', addPostAsAdmin: { __typename?: 'Post', id: string, url: string, title: string, thumbnailUri?: string | null, summary?: string | null, createdAt: any, postCategory: { __typename?: 'PostCategory', id: string, names?: Array<{ __typename?: 'PostCategoryName', languageCode: string, name: string, id: string } | null> | null }, postFrom?: { __typename?: 'PostFrom', id: string, name: string, thumbnail?: { __typename?: 'Media', id: string, uri: string } | null } | null } };

export type GetPostCategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPostCategoriesQuery = { __typename?: 'Query', getPostCategories: Array<{ __typename?: 'PostCategory', id: string, active: boolean, order: number, names?: Array<{ __typename?: 'PostCategoryName', id: string, languageCode: string, name: string } | null> | null } | null> };

export type UpdatePostCategoryAsAdminMutationVariables = Exact<{
  categoryInput: PostCategoryInput;
}>;


export type UpdatePostCategoryAsAdminMutation = { __typename?: 'Mutation', updatePostCategoryAsAdmin: { __typename?: 'PostCategory', id: string, active: boolean, order: number, names?: Array<{ __typename?: 'PostCategoryName', id: string, languageCode: string, name: string } | null> | null } };

export type UpdatePostCategoryNameAsAdminMutationVariables = Exact<{
  categoryNameInput: PostCategoryNameInput;
}>;


export type UpdatePostCategoryNameAsAdminMutation = { __typename?: 'Mutation', updatePostCategoryNameAsAdmin: { __typename?: 'PostCategoryName', id: string, languageCode: string, name: string, postCategory: { __typename?: 'PostCategory', id: string } } };

export type GetPostFromsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPostFromsQuery = { __typename?: 'Query', getPostFroms: Array<{ __typename?: 'PostFrom', id: string, name: string, thumbnail?: { __typename?: 'Media', id: string, uri: string } | null } | null> };

export type UpdatePostFromAsAdminMutationVariables = Exact<{
  postFromInput: PostFromInput;
}>;


export type UpdatePostFromAsAdminMutation = { __typename?: 'Mutation', updatePostFromAsAdmin: { __typename?: 'PostFrom', id: string, name: string, thumbnail?: { __typename?: 'Media', id: string, uri: string } | null } };

export type GetUsersAsAdminQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetUsersAsAdminQuery = { __typename?: 'Query', getUsersAsAdmin: { __typename?: 'UserConnection', pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, endCursor?: string | null }, edges: Array<{ __typename?: 'User', id: string, email?: string | null, name?: string | null, gender?: Gender | null, phoneCode?: string | null, phone?: string | null, dateOfBirth?: any | null, provider?: string | null, resignReason?: string | null, friendsCount: number, createdAt: any, updatedAt: any, deletedAt?: any | null, avatar?: { __typename?: 'Media', id: string, uri: string } | null } | null> } };


export const GetAdCompaniesDocument = gql`
    query GetAdCompanies($first: Int, $after: String) {
  getAdCompanies(first: $first, after: $after) {
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      id
      name
      ceo
      registrationNumber
      url
      phone
      manager
      managerPhone
      adsCount
      createdAt
      updatedAt
    }
  }
}
    `;

/**
 * __useGetAdCompaniesQuery__
 *
 * To run a query within a React component, call `useGetAdCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdCompaniesQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGetAdCompaniesQuery(baseOptions?: Apollo.QueryHookOptions<GetAdCompaniesQuery, GetAdCompaniesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAdCompaniesQuery, GetAdCompaniesQueryVariables>(GetAdCompaniesDocument, options);
      }
export function useGetAdCompaniesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAdCompaniesQuery, GetAdCompaniesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAdCompaniesQuery, GetAdCompaniesQueryVariables>(GetAdCompaniesDocument, options);
        }
export function useGetAdCompaniesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetAdCompaniesQuery, GetAdCompaniesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAdCompaniesQuery, GetAdCompaniesQueryVariables>(GetAdCompaniesDocument, options);
        }
export type GetAdCompaniesQueryHookResult = ReturnType<typeof useGetAdCompaniesQuery>;
export type GetAdCompaniesLazyQueryHookResult = ReturnType<typeof useGetAdCompaniesLazyQuery>;
export type GetAdCompaniesSuspenseQueryHookResult = ReturnType<typeof useGetAdCompaniesSuspenseQuery>;
export type GetAdCompaniesQueryResult = Apollo.QueryResult<GetAdCompaniesQuery, GetAdCompaniesQueryVariables>;
export const GetAdCompanyDocument = gql`
    query GetAdCompany($adCompanyId: ID!) {
  getAdCompany(adCompanyId: $adCompanyId) {
    id
    name
    ceo
    registrationNumber
    url
    phone
    manager
    managerPhone
    adsCount
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useGetAdCompanyQuery__
 *
 * To run a query within a React component, call `useGetAdCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdCompanyQuery({
 *   variables: {
 *      adCompanyId: // value for 'adCompanyId'
 *   },
 * });
 */
export function useGetAdCompanyQuery(baseOptions: Apollo.QueryHookOptions<GetAdCompanyQuery, GetAdCompanyQueryVariables> & ({ variables: GetAdCompanyQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAdCompanyQuery, GetAdCompanyQueryVariables>(GetAdCompanyDocument, options);
      }
export function useGetAdCompanyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAdCompanyQuery, GetAdCompanyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAdCompanyQuery, GetAdCompanyQueryVariables>(GetAdCompanyDocument, options);
        }
export function useGetAdCompanySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetAdCompanyQuery, GetAdCompanyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAdCompanyQuery, GetAdCompanyQueryVariables>(GetAdCompanyDocument, options);
        }
export type GetAdCompanyQueryHookResult = ReturnType<typeof useGetAdCompanyQuery>;
export type GetAdCompanyLazyQueryHookResult = ReturnType<typeof useGetAdCompanyLazyQuery>;
export type GetAdCompanySuspenseQueryHookResult = ReturnType<typeof useGetAdCompanySuspenseQuery>;
export type GetAdCompanyQueryResult = Apollo.QueryResult<GetAdCompanyQuery, GetAdCompanyQueryVariables>;
export const SearchAdCompaniesDocument = gql`
    query SearchAdCompanies($query: String!, $first: Int, $after: String) {
  searchAdCompanies(query: $query, first: $first, after: $after) {
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      id
      name
      ceo
      registrationNumber
      url
      phone
      manager
      managerPhone
      adsCount
      adsCount
      createdAt
      updatedAt
    }
  }
}
    `;

/**
 * __useSearchAdCompaniesQuery__
 *
 * To run a query within a React component, call `useSearchAdCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchAdCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchAdCompaniesQuery({
 *   variables: {
 *      query: // value for 'query'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useSearchAdCompaniesQuery(baseOptions: Apollo.QueryHookOptions<SearchAdCompaniesQuery, SearchAdCompaniesQueryVariables> & ({ variables: SearchAdCompaniesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchAdCompaniesQuery, SearchAdCompaniesQueryVariables>(SearchAdCompaniesDocument, options);
      }
export function useSearchAdCompaniesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchAdCompaniesQuery, SearchAdCompaniesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchAdCompaniesQuery, SearchAdCompaniesQueryVariables>(SearchAdCompaniesDocument, options);
        }
export function useSearchAdCompaniesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SearchAdCompaniesQuery, SearchAdCompaniesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SearchAdCompaniesQuery, SearchAdCompaniesQueryVariables>(SearchAdCompaniesDocument, options);
        }
export type SearchAdCompaniesQueryHookResult = ReturnType<typeof useSearchAdCompaniesQuery>;
export type SearchAdCompaniesLazyQueryHookResult = ReturnType<typeof useSearchAdCompaniesLazyQuery>;
export type SearchAdCompaniesSuspenseQueryHookResult = ReturnType<typeof useSearchAdCompaniesSuspenseQuery>;
export type SearchAdCompaniesQueryResult = Apollo.QueryResult<SearchAdCompaniesQuery, SearchAdCompaniesQueryVariables>;
export const GetAllAdvertisesAsAdminDocument = gql`
    query GetAllAdvertisesAsAdmin($first: Int, $after: String) {
  getAllAdvertisesAsAdmin(first: $first, after: $after) {
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      id
      company {
        id
        name
      }
      imageUrl
      url
      title
      description
      from
      to
      createdAt
      updatedAt
    }
  }
}
    `;

/**
 * __useGetAllAdvertisesAsAdminQuery__
 *
 * To run a query within a React component, call `useGetAllAdvertisesAsAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllAdvertisesAsAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllAdvertisesAsAdminQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGetAllAdvertisesAsAdminQuery(baseOptions?: Apollo.QueryHookOptions<GetAllAdvertisesAsAdminQuery, GetAllAdvertisesAsAdminQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllAdvertisesAsAdminQuery, GetAllAdvertisesAsAdminQueryVariables>(GetAllAdvertisesAsAdminDocument, options);
      }
export function useGetAllAdvertisesAsAdminLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllAdvertisesAsAdminQuery, GetAllAdvertisesAsAdminQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllAdvertisesAsAdminQuery, GetAllAdvertisesAsAdminQueryVariables>(GetAllAdvertisesAsAdminDocument, options);
        }
export function useGetAllAdvertisesAsAdminSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetAllAdvertisesAsAdminQuery, GetAllAdvertisesAsAdminQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAllAdvertisesAsAdminQuery, GetAllAdvertisesAsAdminQueryVariables>(GetAllAdvertisesAsAdminDocument, options);
        }
export type GetAllAdvertisesAsAdminQueryHookResult = ReturnType<typeof useGetAllAdvertisesAsAdminQuery>;
export type GetAllAdvertisesAsAdminLazyQueryHookResult = ReturnType<typeof useGetAllAdvertisesAsAdminLazyQuery>;
export type GetAllAdvertisesAsAdminSuspenseQueryHookResult = ReturnType<typeof useGetAllAdvertisesAsAdminSuspenseQuery>;
export type GetAllAdvertisesAsAdminQueryResult = Apollo.QueryResult<GetAllAdvertisesAsAdminQuery, GetAllAdvertisesAsAdminQueryVariables>;
export const GetAdvertiseDocument = gql`
    query GetAdvertise($advertiseId: ID!) {
  getAdvertise(advertiseId: $advertiseId) {
    id
    company {
      id
      name
    }
    imageUrl
    url
    title
    description
    from
    to
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useGetAdvertiseQuery__
 *
 * To run a query within a React component, call `useGetAdvertiseQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdvertiseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdvertiseQuery({
 *   variables: {
 *      advertiseId: // value for 'advertiseId'
 *   },
 * });
 */
export function useGetAdvertiseQuery(baseOptions: Apollo.QueryHookOptions<GetAdvertiseQuery, GetAdvertiseQueryVariables> & ({ variables: GetAdvertiseQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAdvertiseQuery, GetAdvertiseQueryVariables>(GetAdvertiseDocument, options);
      }
export function useGetAdvertiseLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAdvertiseQuery, GetAdvertiseQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAdvertiseQuery, GetAdvertiseQueryVariables>(GetAdvertiseDocument, options);
        }
export function useGetAdvertiseSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetAdvertiseQuery, GetAdvertiseQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAdvertiseQuery, GetAdvertiseQueryVariables>(GetAdvertiseDocument, options);
        }
export type GetAdvertiseQueryHookResult = ReturnType<typeof useGetAdvertiseQuery>;
export type GetAdvertiseLazyQueryHookResult = ReturnType<typeof useGetAdvertiseLazyQuery>;
export type GetAdvertiseSuspenseQueryHookResult = ReturnType<typeof useGetAdvertiseSuspenseQuery>;
export type GetAdvertiseQueryResult = Apollo.QueryResult<GetAdvertiseQuery, GetAdvertiseQueryVariables>;
export const UpdateAdCompanyAsAdminDocument = gql`
    mutation UpdateAdCompanyAsAdmin($adCompanyInput: AdCompanyInput!) {
  updateAdCompanyAsAdmin(adCompanyInput: $adCompanyInput) {
    id
    name
    ceo
    registrationNumber
    url
    phone
    manager
    managerPhone
    adsCount
    createdAt
    updatedAt
  }
}
    `;
export type UpdateAdCompanyAsAdminMutationFn = Apollo.MutationFunction<UpdateAdCompanyAsAdminMutation, UpdateAdCompanyAsAdminMutationVariables>;

/**
 * __useUpdateAdCompanyAsAdminMutation__
 *
 * To run a mutation, you first call `useUpdateAdCompanyAsAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAdCompanyAsAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAdCompanyAsAdminMutation, { data, loading, error }] = useUpdateAdCompanyAsAdminMutation({
 *   variables: {
 *      adCompanyInput: // value for 'adCompanyInput'
 *   },
 * });
 */
export function useUpdateAdCompanyAsAdminMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAdCompanyAsAdminMutation, UpdateAdCompanyAsAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAdCompanyAsAdminMutation, UpdateAdCompanyAsAdminMutationVariables>(UpdateAdCompanyAsAdminDocument, options);
      }
export type UpdateAdCompanyAsAdminMutationHookResult = ReturnType<typeof useUpdateAdCompanyAsAdminMutation>;
export type UpdateAdCompanyAsAdminMutationResult = Apollo.MutationResult<UpdateAdCompanyAsAdminMutation>;
export type UpdateAdCompanyAsAdminMutationOptions = Apollo.BaseMutationOptions<UpdateAdCompanyAsAdminMutation, UpdateAdCompanyAsAdminMutationVariables>;
export const UpdateAdvertiseAsAdminDocument = gql`
    mutation UpdateAdvertiseAsAdmin($advertiseInput: AdvertiseInput!) {
  updateAdvertiseAsAdmin(advertiseInput: $advertiseInput) {
    id
    company {
      id
      name
    }
    imageUrl
    url
    title
    description
    from
    to
    createdAt
    updatedAt
  }
}
    `;
export type UpdateAdvertiseAsAdminMutationFn = Apollo.MutationFunction<UpdateAdvertiseAsAdminMutation, UpdateAdvertiseAsAdminMutationVariables>;

/**
 * __useUpdateAdvertiseAsAdminMutation__
 *
 * To run a mutation, you first call `useUpdateAdvertiseAsAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAdvertiseAsAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAdvertiseAsAdminMutation, { data, loading, error }] = useUpdateAdvertiseAsAdminMutation({
 *   variables: {
 *      advertiseInput: // value for 'advertiseInput'
 *   },
 * });
 */
export function useUpdateAdvertiseAsAdminMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAdvertiseAsAdminMutation, UpdateAdvertiseAsAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAdvertiseAsAdminMutation, UpdateAdvertiseAsAdminMutationVariables>(UpdateAdvertiseAsAdminDocument, options);
      }
export type UpdateAdvertiseAsAdminMutationHookResult = ReturnType<typeof useUpdateAdvertiseAsAdminMutation>;
export type UpdateAdvertiseAsAdminMutationResult = Apollo.MutationResult<UpdateAdvertiseAsAdminMutation>;
export type UpdateAdvertiseAsAdminMutationOptions = Apollo.BaseMutationOptions<UpdateAdvertiseAsAdminMutation, UpdateAdvertiseAsAdminMutationVariables>;
export const GetAnnouncementsDocument = gql`
    query GetAnnouncements($activeOnly: Boolean!, $first: Int, $after: String) {
  getAnnouncements(activeOnly: $activeOnly, first: $first, after: $after) {
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      id
      title
      body
      active
      createdAt
      updatedAt
    }
  }
}
    `;

/**
 * __useGetAnnouncementsQuery__
 *
 * To run a query within a React component, call `useGetAnnouncementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAnnouncementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAnnouncementsQuery({
 *   variables: {
 *      activeOnly: // value for 'activeOnly'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGetAnnouncementsQuery(baseOptions: Apollo.QueryHookOptions<GetAnnouncementsQuery, GetAnnouncementsQueryVariables> & ({ variables: GetAnnouncementsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAnnouncementsQuery, GetAnnouncementsQueryVariables>(GetAnnouncementsDocument, options);
      }
export function useGetAnnouncementsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAnnouncementsQuery, GetAnnouncementsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAnnouncementsQuery, GetAnnouncementsQueryVariables>(GetAnnouncementsDocument, options);
        }
export function useGetAnnouncementsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetAnnouncementsQuery, GetAnnouncementsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAnnouncementsQuery, GetAnnouncementsQueryVariables>(GetAnnouncementsDocument, options);
        }
export type GetAnnouncementsQueryHookResult = ReturnType<typeof useGetAnnouncementsQuery>;
export type GetAnnouncementsLazyQueryHookResult = ReturnType<typeof useGetAnnouncementsLazyQuery>;
export type GetAnnouncementsSuspenseQueryHookResult = ReturnType<typeof useGetAnnouncementsSuspenseQuery>;
export type GetAnnouncementsQueryResult = Apollo.QueryResult<GetAnnouncementsQuery, GetAnnouncementsQueryVariables>;
export const GetAnnouncementDocument = gql`
    query GetAnnouncement($announcementId: ID!) {
  getAnnouncement(announcementId: $announcementId) {
    id
    title
    body
    active
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useGetAnnouncementQuery__
 *
 * To run a query within a React component, call `useGetAnnouncementQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAnnouncementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAnnouncementQuery({
 *   variables: {
 *      announcementId: // value for 'announcementId'
 *   },
 * });
 */
export function useGetAnnouncementQuery(baseOptions: Apollo.QueryHookOptions<GetAnnouncementQuery, GetAnnouncementQueryVariables> & ({ variables: GetAnnouncementQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAnnouncementQuery, GetAnnouncementQueryVariables>(GetAnnouncementDocument, options);
      }
export function useGetAnnouncementLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAnnouncementQuery, GetAnnouncementQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAnnouncementQuery, GetAnnouncementQueryVariables>(GetAnnouncementDocument, options);
        }
export function useGetAnnouncementSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetAnnouncementQuery, GetAnnouncementQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAnnouncementQuery, GetAnnouncementQueryVariables>(GetAnnouncementDocument, options);
        }
export type GetAnnouncementQueryHookResult = ReturnType<typeof useGetAnnouncementQuery>;
export type GetAnnouncementLazyQueryHookResult = ReturnType<typeof useGetAnnouncementLazyQuery>;
export type GetAnnouncementSuspenseQueryHookResult = ReturnType<typeof useGetAnnouncementSuspenseQuery>;
export type GetAnnouncementQueryResult = Apollo.QueryResult<GetAnnouncementQuery, GetAnnouncementQueryVariables>;
export const GetAllNoticesAsAdminDocument = gql`
    query GetAllNoticesAsAdmin($first: Int, $after: String) {
  getAllNoticesAsAdmin(first: $first, after: $after) {
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      id
      announcement {
        id
        title
      }
      imageUrl
      externalUrl
      active
      createdAt
      updatedAt
    }
  }
}
    `;

/**
 * __useGetAllNoticesAsAdminQuery__
 *
 * To run a query within a React component, call `useGetAllNoticesAsAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllNoticesAsAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllNoticesAsAdminQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGetAllNoticesAsAdminQuery(baseOptions?: Apollo.QueryHookOptions<GetAllNoticesAsAdminQuery, GetAllNoticesAsAdminQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllNoticesAsAdminQuery, GetAllNoticesAsAdminQueryVariables>(GetAllNoticesAsAdminDocument, options);
      }
export function useGetAllNoticesAsAdminLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllNoticesAsAdminQuery, GetAllNoticesAsAdminQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllNoticesAsAdminQuery, GetAllNoticesAsAdminQueryVariables>(GetAllNoticesAsAdminDocument, options);
        }
export function useGetAllNoticesAsAdminSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetAllNoticesAsAdminQuery, GetAllNoticesAsAdminQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAllNoticesAsAdminQuery, GetAllNoticesAsAdminQueryVariables>(GetAllNoticesAsAdminDocument, options);
        }
export type GetAllNoticesAsAdminQueryHookResult = ReturnType<typeof useGetAllNoticesAsAdminQuery>;
export type GetAllNoticesAsAdminLazyQueryHookResult = ReturnType<typeof useGetAllNoticesAsAdminLazyQuery>;
export type GetAllNoticesAsAdminSuspenseQueryHookResult = ReturnType<typeof useGetAllNoticesAsAdminSuspenseQuery>;
export type GetAllNoticesAsAdminQueryResult = Apollo.QueryResult<GetAllNoticesAsAdminQuery, GetAllNoticesAsAdminQueryVariables>;
export const UpdateAnnouncementAsAdminDocument = gql`
    mutation UpdateAnnouncementAsAdmin($announcementInput: AnnouncementInput!) {
  updateAnnouncementAsAdmin(announcementInput: $announcementInput) {
    id
    title
    body
    active
    createdAt
    updatedAt
  }
}
    `;
export type UpdateAnnouncementAsAdminMutationFn = Apollo.MutationFunction<UpdateAnnouncementAsAdminMutation, UpdateAnnouncementAsAdminMutationVariables>;

/**
 * __useUpdateAnnouncementAsAdminMutation__
 *
 * To run a mutation, you first call `useUpdateAnnouncementAsAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAnnouncementAsAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAnnouncementAsAdminMutation, { data, loading, error }] = useUpdateAnnouncementAsAdminMutation({
 *   variables: {
 *      announcementInput: // value for 'announcementInput'
 *   },
 * });
 */
export function useUpdateAnnouncementAsAdminMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAnnouncementAsAdminMutation, UpdateAnnouncementAsAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAnnouncementAsAdminMutation, UpdateAnnouncementAsAdminMutationVariables>(UpdateAnnouncementAsAdminDocument, options);
      }
export type UpdateAnnouncementAsAdminMutationHookResult = ReturnType<typeof useUpdateAnnouncementAsAdminMutation>;
export type UpdateAnnouncementAsAdminMutationResult = Apollo.MutationResult<UpdateAnnouncementAsAdminMutation>;
export type UpdateAnnouncementAsAdminMutationOptions = Apollo.BaseMutationOptions<UpdateAnnouncementAsAdminMutation, UpdateAnnouncementAsAdminMutationVariables>;
export const UpdateNoticeAsAdminDocument = gql`
    mutation UpdateNoticeAsAdmin($noticeInput: NoticeInput!) {
  updateNoticeAsAdmin(noticeInput: $noticeInput) {
    id
    announcement {
      id
      title
    }
    imageUrl
    externalUrl
    active
    createdAt
    updatedAt
  }
}
    `;
export type UpdateNoticeAsAdminMutationFn = Apollo.MutationFunction<UpdateNoticeAsAdminMutation, UpdateNoticeAsAdminMutationVariables>;

/**
 * __useUpdateNoticeAsAdminMutation__
 *
 * To run a mutation, you first call `useUpdateNoticeAsAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNoticeAsAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNoticeAsAdminMutation, { data, loading, error }] = useUpdateNoticeAsAdminMutation({
 *   variables: {
 *      noticeInput: // value for 'noticeInput'
 *   },
 * });
 */
export function useUpdateNoticeAsAdminMutation(baseOptions?: Apollo.MutationHookOptions<UpdateNoticeAsAdminMutation, UpdateNoticeAsAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateNoticeAsAdminMutation, UpdateNoticeAsAdminMutationVariables>(UpdateNoticeAsAdminDocument, options);
      }
export type UpdateNoticeAsAdminMutationHookResult = ReturnType<typeof useUpdateNoticeAsAdminMutation>;
export type UpdateNoticeAsAdminMutationResult = Apollo.MutationResult<UpdateNoticeAsAdminMutation>;
export type UpdateNoticeAsAdminMutationOptions = Apollo.BaseMutationOptions<UpdateNoticeAsAdminMutation, UpdateNoticeAsAdminMutationVariables>;
export const GetManagementDocument = gql`
    query GetManagement {
  getManagement {
    id
    companyName
    address
    copyright
    email
    ceoName
    registrationNumber
    escroRegistration
    phone
    kakaoAddress
    shopAddress
    instagramAddress
  }
}
    `;

/**
 * __useGetManagementQuery__
 *
 * To run a query within a React component, call `useGetManagementQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManagementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManagementQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetManagementQuery(baseOptions?: Apollo.QueryHookOptions<GetManagementQuery, GetManagementQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetManagementQuery, GetManagementQueryVariables>(GetManagementDocument, options);
      }
export function useGetManagementLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetManagementQuery, GetManagementQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetManagementQuery, GetManagementQueryVariables>(GetManagementDocument, options);
        }
export function useGetManagementSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetManagementQuery, GetManagementQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetManagementQuery, GetManagementQueryVariables>(GetManagementDocument, options);
        }
export type GetManagementQueryHookResult = ReturnType<typeof useGetManagementQuery>;
export type GetManagementLazyQueryHookResult = ReturnType<typeof useGetManagementLazyQuery>;
export type GetManagementSuspenseQueryHookResult = ReturnType<typeof useGetManagementSuspenseQuery>;
export type GetManagementQueryResult = Apollo.QueryResult<GetManagementQuery, GetManagementQueryVariables>;
export const UpdateManagementAsAdminDocument = gql`
    mutation UpdateManagementAsAdmin($managementInput: ManagementInput!) {
  updateManagementAsAdmin(managementInput: $managementInput) {
    id
    companyName
    address
    copyright
    email
    ceoName
    registrationNumber
    escroRegistration
    phone
    kakaoAddress
    shopAddress
    instagramAddress
  }
}
    `;
export type UpdateManagementAsAdminMutationFn = Apollo.MutationFunction<UpdateManagementAsAdminMutation, UpdateManagementAsAdminMutationVariables>;

/**
 * __useUpdateManagementAsAdminMutation__
 *
 * To run a mutation, you first call `useUpdateManagementAsAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateManagementAsAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateManagementAsAdminMutation, { data, loading, error }] = useUpdateManagementAsAdminMutation({
 *   variables: {
 *      managementInput: // value for 'managementInput'
 *   },
 * });
 */
export function useUpdateManagementAsAdminMutation(baseOptions?: Apollo.MutationHookOptions<UpdateManagementAsAdminMutation, UpdateManagementAsAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateManagementAsAdminMutation, UpdateManagementAsAdminMutationVariables>(UpdateManagementAsAdminDocument, options);
      }
export type UpdateManagementAsAdminMutationHookResult = ReturnType<typeof useUpdateManagementAsAdminMutation>;
export type UpdateManagementAsAdminMutationResult = Apollo.MutationResult<UpdateManagementAsAdminMutation>;
export type UpdateManagementAsAdminMutationOptions = Apollo.BaseMutationOptions<UpdateManagementAsAdminMutation, UpdateManagementAsAdminMutationVariables>;
export const GetCategoriesDocument = gql`
    query GetCategories {
  getCategories {
    id
    names {
      id
      languageCode
      name
    }
    active
    order
  }
}
    `;

/**
 * __useGetCategoriesQuery__
 *
 * To run a query within a React component, call `useGetCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<GetCategoriesQuery, GetCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCategoriesQuery, GetCategoriesQueryVariables>(GetCategoriesDocument, options);
      }
export function useGetCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCategoriesQuery, GetCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCategoriesQuery, GetCategoriesQueryVariables>(GetCategoriesDocument, options);
        }
export function useGetCategoriesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetCategoriesQuery, GetCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCategoriesQuery, GetCategoriesQueryVariables>(GetCategoriesDocument, options);
        }
export type GetCategoriesQueryHookResult = ReturnType<typeof useGetCategoriesQuery>;
export type GetCategoriesLazyQueryHookResult = ReturnType<typeof useGetCategoriesLazyQuery>;
export type GetCategoriesSuspenseQueryHookResult = ReturnType<typeof useGetCategoriesSuspenseQuery>;
export type GetCategoriesQueryResult = Apollo.QueryResult<GetCategoriesQuery, GetCategoriesQueryVariables>;
export const GetPurposesDocument = gql`
    query GetPurposes {
  getPurposes {
    id
    names {
      id
      languageCode
      name
    }
    active
    order
  }
}
    `;

/**
 * __useGetPurposesQuery__
 *
 * To run a query within a React component, call `useGetPurposesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPurposesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPurposesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPurposesQuery(baseOptions?: Apollo.QueryHookOptions<GetPurposesQuery, GetPurposesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPurposesQuery, GetPurposesQueryVariables>(GetPurposesDocument, options);
      }
export function useGetPurposesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPurposesQuery, GetPurposesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPurposesQuery, GetPurposesQueryVariables>(GetPurposesDocument, options);
        }
export function useGetPurposesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetPurposesQuery, GetPurposesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPurposesQuery, GetPurposesQueryVariables>(GetPurposesDocument, options);
        }
export type GetPurposesQueryHookResult = ReturnType<typeof useGetPurposesQuery>;
export type GetPurposesLazyQueryHookResult = ReturnType<typeof useGetPurposesLazyQuery>;
export type GetPurposesSuspenseQueryHookResult = ReturnType<typeof useGetPurposesSuspenseQuery>;
export type GetPurposesQueryResult = Apollo.QueryResult<GetPurposesQuery, GetPurposesQueryVariables>;
export const UpdatePurposeAsAdminDocument = gql`
    mutation UpdatePurposeAsAdmin($purposeInput: PurposeInput!) {
  updatePurposeAsAdmin(purposeInput: $purposeInput) {
    id
    names {
      id
      languageCode
      name
    }
    active
    order
  }
}
    `;
export type UpdatePurposeAsAdminMutationFn = Apollo.MutationFunction<UpdatePurposeAsAdminMutation, UpdatePurposeAsAdminMutationVariables>;

/**
 * __useUpdatePurposeAsAdminMutation__
 *
 * To run a mutation, you first call `useUpdatePurposeAsAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePurposeAsAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePurposeAsAdminMutation, { data, loading, error }] = useUpdatePurposeAsAdminMutation({
 *   variables: {
 *      purposeInput: // value for 'purposeInput'
 *   },
 * });
 */
export function useUpdatePurposeAsAdminMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePurposeAsAdminMutation, UpdatePurposeAsAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePurposeAsAdminMutation, UpdatePurposeAsAdminMutationVariables>(UpdatePurposeAsAdminDocument, options);
      }
export type UpdatePurposeAsAdminMutationHookResult = ReturnType<typeof useUpdatePurposeAsAdminMutation>;
export type UpdatePurposeAsAdminMutationResult = Apollo.MutationResult<UpdatePurposeAsAdminMutation>;
export type UpdatePurposeAsAdminMutationOptions = Apollo.BaseMutationOptions<UpdatePurposeAsAdminMutation, UpdatePurposeAsAdminMutationVariables>;
export const UpdatePurposeNameAsAdminDocument = gql`
    mutation UpdatePurposeNameAsAdmin($purposeNameInput: PurposeNameInput!) {
  updatePurposeNameAsAdmin(purposeNameInput: $purposeNameInput) {
    id
    purpose {
      id
    }
    languageCode
    name
  }
}
    `;
export type UpdatePurposeNameAsAdminMutationFn = Apollo.MutationFunction<UpdatePurposeNameAsAdminMutation, UpdatePurposeNameAsAdminMutationVariables>;

/**
 * __useUpdatePurposeNameAsAdminMutation__
 *
 * To run a mutation, you first call `useUpdatePurposeNameAsAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePurposeNameAsAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePurposeNameAsAdminMutation, { data, loading, error }] = useUpdatePurposeNameAsAdminMutation({
 *   variables: {
 *      purposeNameInput: // value for 'purposeNameInput'
 *   },
 * });
 */
export function useUpdatePurposeNameAsAdminMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePurposeNameAsAdminMutation, UpdatePurposeNameAsAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePurposeNameAsAdminMutation, UpdatePurposeNameAsAdminMutationVariables>(UpdatePurposeNameAsAdminDocument, options);
      }
export type UpdatePurposeNameAsAdminMutationHookResult = ReturnType<typeof useUpdatePurposeNameAsAdminMutation>;
export type UpdatePurposeNameAsAdminMutationResult = Apollo.MutationResult<UpdatePurposeNameAsAdminMutation>;
export type UpdatePurposeNameAsAdminMutationOptions = Apollo.BaseMutationOptions<UpdatePurposeNameAsAdminMutation, UpdatePurposeNameAsAdminMutationVariables>;
export const UpdateCategoryAsAdminDocument = gql`
    mutation UpdateCategoryAsAdmin($categoryInput: CategoryInput!) {
  updateCategoryAsAdmin(categoryInput: $categoryInput) {
    id
    names {
      id
      languageCode
      name
    }
    active
    order
  }
}
    `;
export type UpdateCategoryAsAdminMutationFn = Apollo.MutationFunction<UpdateCategoryAsAdminMutation, UpdateCategoryAsAdminMutationVariables>;

/**
 * __useUpdateCategoryAsAdminMutation__
 *
 * To run a mutation, you first call `useUpdateCategoryAsAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCategoryAsAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCategoryAsAdminMutation, { data, loading, error }] = useUpdateCategoryAsAdminMutation({
 *   variables: {
 *      categoryInput: // value for 'categoryInput'
 *   },
 * });
 */
export function useUpdateCategoryAsAdminMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCategoryAsAdminMutation, UpdateCategoryAsAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCategoryAsAdminMutation, UpdateCategoryAsAdminMutationVariables>(UpdateCategoryAsAdminDocument, options);
      }
export type UpdateCategoryAsAdminMutationHookResult = ReturnType<typeof useUpdateCategoryAsAdminMutation>;
export type UpdateCategoryAsAdminMutationResult = Apollo.MutationResult<UpdateCategoryAsAdminMutation>;
export type UpdateCategoryAsAdminMutationOptions = Apollo.BaseMutationOptions<UpdateCategoryAsAdminMutation, UpdateCategoryAsAdminMutationVariables>;
export const UpdateCategoryNameAsAdminDocument = gql`
    mutation UpdateCategoryNameAsAdmin($categoryNameInput: CategoryNameInput!) {
  updateCategoryNameAsAdmin(categoryNameInput: $categoryNameInput) {
    id
    category {
      id
    }
    languageCode
    name
  }
}
    `;
export type UpdateCategoryNameAsAdminMutationFn = Apollo.MutationFunction<UpdateCategoryNameAsAdminMutation, UpdateCategoryNameAsAdminMutationVariables>;

/**
 * __useUpdateCategoryNameAsAdminMutation__
 *
 * To run a mutation, you first call `useUpdateCategoryNameAsAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCategoryNameAsAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCategoryNameAsAdminMutation, { data, loading, error }] = useUpdateCategoryNameAsAdminMutation({
 *   variables: {
 *      categoryNameInput: // value for 'categoryNameInput'
 *   },
 * });
 */
export function useUpdateCategoryNameAsAdminMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCategoryNameAsAdminMutation, UpdateCategoryNameAsAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCategoryNameAsAdminMutation, UpdateCategoryNameAsAdminMutationVariables>(UpdateCategoryNameAsAdminDocument, options);
      }
export type UpdateCategoryNameAsAdminMutationHookResult = ReturnType<typeof useUpdateCategoryNameAsAdminMutation>;
export type UpdateCategoryNameAsAdminMutationResult = Apollo.MutationResult<UpdateCategoryNameAsAdminMutation>;
export type UpdateCategoryNameAsAdminMutationOptions = Apollo.BaseMutationOptions<UpdateCategoryNameAsAdminMutation, UpdateCategoryNameAsAdminMutationVariables>;
export const GetPoliciesDocument = gql`
    query GetPolicies($type: PolicyType!) {
  getPolicies(type: $type) {
    id
    type
    body
    createdAt
  }
}
    `;

/**
 * __useGetPoliciesQuery__
 *
 * To run a query within a React component, call `useGetPoliciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPoliciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPoliciesQuery({
 *   variables: {
 *      type: // value for 'type'
 *   },
 * });
 */
export function useGetPoliciesQuery(baseOptions: Apollo.QueryHookOptions<GetPoliciesQuery, GetPoliciesQueryVariables> & ({ variables: GetPoliciesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPoliciesQuery, GetPoliciesQueryVariables>(GetPoliciesDocument, options);
      }
export function useGetPoliciesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPoliciesQuery, GetPoliciesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPoliciesQuery, GetPoliciesQueryVariables>(GetPoliciesDocument, options);
        }
export function useGetPoliciesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetPoliciesQuery, GetPoliciesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPoliciesQuery, GetPoliciesQueryVariables>(GetPoliciesDocument, options);
        }
export type GetPoliciesQueryHookResult = ReturnType<typeof useGetPoliciesQuery>;
export type GetPoliciesLazyQueryHookResult = ReturnType<typeof useGetPoliciesLazyQuery>;
export type GetPoliciesSuspenseQueryHookResult = ReturnType<typeof useGetPoliciesSuspenseQuery>;
export type GetPoliciesQueryResult = Apollo.QueryResult<GetPoliciesQuery, GetPoliciesQueryVariables>;
export const GetLatestPolicyDocument = gql`
    query GetLatestPolicy($type: PolicyType!) {
  getLatestPolicy(type: $type) {
    id
    type
    body
    createdAt
  }
}
    `;

/**
 * __useGetLatestPolicyQuery__
 *
 * To run a query within a React component, call `useGetLatestPolicyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLatestPolicyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLatestPolicyQuery({
 *   variables: {
 *      type: // value for 'type'
 *   },
 * });
 */
export function useGetLatestPolicyQuery(baseOptions: Apollo.QueryHookOptions<GetLatestPolicyQuery, GetLatestPolicyQueryVariables> & ({ variables: GetLatestPolicyQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLatestPolicyQuery, GetLatestPolicyQueryVariables>(GetLatestPolicyDocument, options);
      }
export function useGetLatestPolicyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLatestPolicyQuery, GetLatestPolicyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLatestPolicyQuery, GetLatestPolicyQueryVariables>(GetLatestPolicyDocument, options);
        }
export function useGetLatestPolicySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetLatestPolicyQuery, GetLatestPolicyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetLatestPolicyQuery, GetLatestPolicyQueryVariables>(GetLatestPolicyDocument, options);
        }
export type GetLatestPolicyQueryHookResult = ReturnType<typeof useGetLatestPolicyQuery>;
export type GetLatestPolicyLazyQueryHookResult = ReturnType<typeof useGetLatestPolicyLazyQuery>;
export type GetLatestPolicySuspenseQueryHookResult = ReturnType<typeof useGetLatestPolicySuspenseQuery>;
export type GetLatestPolicyQueryResult = Apollo.QueryResult<GetLatestPolicyQuery, GetLatestPolicyQueryVariables>;
export const UpdatePolicyAsAdminDocument = gql`
    mutation UpdatePolicyAsAdmin($policyInput: PolicyInput!) {
  updatePolicyAsAdmin(policyInput: $policyInput) {
    id
    type
    body
    createdAt
  }
}
    `;
export type UpdatePolicyAsAdminMutationFn = Apollo.MutationFunction<UpdatePolicyAsAdminMutation, UpdatePolicyAsAdminMutationVariables>;

/**
 * __useUpdatePolicyAsAdminMutation__
 *
 * To run a mutation, you first call `useUpdatePolicyAsAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePolicyAsAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePolicyAsAdminMutation, { data, loading, error }] = useUpdatePolicyAsAdminMutation({
 *   variables: {
 *      policyInput: // value for 'policyInput'
 *   },
 * });
 */
export function useUpdatePolicyAsAdminMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePolicyAsAdminMutation, UpdatePolicyAsAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePolicyAsAdminMutation, UpdatePolicyAsAdminMutationVariables>(UpdatePolicyAsAdminDocument, options);
      }
export type UpdatePolicyAsAdminMutationHookResult = ReturnType<typeof useUpdatePolicyAsAdminMutation>;
export type UpdatePolicyAsAdminMutationResult = Apollo.MutationResult<UpdatePolicyAsAdminMutation>;
export type UpdatePolicyAsAdminMutationOptions = Apollo.BaseMutationOptions<UpdatePolicyAsAdminMutation, UpdatePolicyAsAdminMutationVariables>;
export const GetPostsDocument = gql`
    query GetPosts($postCategoryId: ID, $postFromId: ID, $first: Int, $after: String) {
  getPosts(
    postCategoryId: $postCategoryId
    postFromId: $postFromId
    first: $first
    after: $after
  ) {
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      id
      postCategory {
        id
        names {
          languageCode
          name
          id
        }
      }
      postFrom {
        id
        name
        thumbnail {
          id
          uri
        }
      }
      url
      title
      thumbnailUri
      summary
      createdAt
    }
  }
}
    `;

/**
 * __useGetPostsQuery__
 *
 * To run a query within a React component, call `useGetPostsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPostsQuery({
 *   variables: {
 *      postCategoryId: // value for 'postCategoryId'
 *      postFromId: // value for 'postFromId'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGetPostsQuery(baseOptions?: Apollo.QueryHookOptions<GetPostsQuery, GetPostsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPostsQuery, GetPostsQueryVariables>(GetPostsDocument, options);
      }
export function useGetPostsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPostsQuery, GetPostsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPostsQuery, GetPostsQueryVariables>(GetPostsDocument, options);
        }
export function useGetPostsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetPostsQuery, GetPostsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPostsQuery, GetPostsQueryVariables>(GetPostsDocument, options);
        }
export type GetPostsQueryHookResult = ReturnType<typeof useGetPostsQuery>;
export type GetPostsLazyQueryHookResult = ReturnType<typeof useGetPostsLazyQuery>;
export type GetPostsSuspenseQueryHookResult = ReturnType<typeof useGetPostsSuspenseQuery>;
export type GetPostsQueryResult = Apollo.QueryResult<GetPostsQuery, GetPostsQueryVariables>;
export const AddPostAsAdminDocument = gql`
    mutation AddPostAsAdmin($postInput: PostInput!) {
  addPostAsAdmin(postInput: $postInput) {
    id
    postCategory {
      id
      names {
        languageCode
        name
        id
      }
    }
    postFrom {
      id
      name
      thumbnail {
        id
        uri
      }
    }
    url
    title
    thumbnailUri
    summary
    createdAt
  }
}
    `;
export type AddPostAsAdminMutationFn = Apollo.MutationFunction<AddPostAsAdminMutation, AddPostAsAdminMutationVariables>;

/**
 * __useAddPostAsAdminMutation__
 *
 * To run a mutation, you first call `useAddPostAsAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPostAsAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPostAsAdminMutation, { data, loading, error }] = useAddPostAsAdminMutation({
 *   variables: {
 *      postInput: // value for 'postInput'
 *   },
 * });
 */
export function useAddPostAsAdminMutation(baseOptions?: Apollo.MutationHookOptions<AddPostAsAdminMutation, AddPostAsAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddPostAsAdminMutation, AddPostAsAdminMutationVariables>(AddPostAsAdminDocument, options);
      }
export type AddPostAsAdminMutationHookResult = ReturnType<typeof useAddPostAsAdminMutation>;
export type AddPostAsAdminMutationResult = Apollo.MutationResult<AddPostAsAdminMutation>;
export type AddPostAsAdminMutationOptions = Apollo.BaseMutationOptions<AddPostAsAdminMutation, AddPostAsAdminMutationVariables>;
export const GetPostCategoriesDocument = gql`
    query GetPostCategories {
  getPostCategories {
    id
    names {
      id
      languageCode
      name
    }
    active
    order
  }
}
    `;

/**
 * __useGetPostCategoriesQuery__
 *
 * To run a query within a React component, call `useGetPostCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPostCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPostCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPostCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<GetPostCategoriesQuery, GetPostCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPostCategoriesQuery, GetPostCategoriesQueryVariables>(GetPostCategoriesDocument, options);
      }
export function useGetPostCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPostCategoriesQuery, GetPostCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPostCategoriesQuery, GetPostCategoriesQueryVariables>(GetPostCategoriesDocument, options);
        }
export function useGetPostCategoriesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetPostCategoriesQuery, GetPostCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPostCategoriesQuery, GetPostCategoriesQueryVariables>(GetPostCategoriesDocument, options);
        }
export type GetPostCategoriesQueryHookResult = ReturnType<typeof useGetPostCategoriesQuery>;
export type GetPostCategoriesLazyQueryHookResult = ReturnType<typeof useGetPostCategoriesLazyQuery>;
export type GetPostCategoriesSuspenseQueryHookResult = ReturnType<typeof useGetPostCategoriesSuspenseQuery>;
export type GetPostCategoriesQueryResult = Apollo.QueryResult<GetPostCategoriesQuery, GetPostCategoriesQueryVariables>;
export const UpdatePostCategoryAsAdminDocument = gql`
    mutation UpdatePostCategoryAsAdmin($categoryInput: PostCategoryInput!) {
  updatePostCategoryAsAdmin(categoryInput: $categoryInput) {
    id
    names {
      id
      languageCode
      name
    }
    active
    order
  }
}
    `;
export type UpdatePostCategoryAsAdminMutationFn = Apollo.MutationFunction<UpdatePostCategoryAsAdminMutation, UpdatePostCategoryAsAdminMutationVariables>;

/**
 * __useUpdatePostCategoryAsAdminMutation__
 *
 * To run a mutation, you first call `useUpdatePostCategoryAsAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePostCategoryAsAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePostCategoryAsAdminMutation, { data, loading, error }] = useUpdatePostCategoryAsAdminMutation({
 *   variables: {
 *      categoryInput: // value for 'categoryInput'
 *   },
 * });
 */
export function useUpdatePostCategoryAsAdminMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePostCategoryAsAdminMutation, UpdatePostCategoryAsAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePostCategoryAsAdminMutation, UpdatePostCategoryAsAdminMutationVariables>(UpdatePostCategoryAsAdminDocument, options);
      }
export type UpdatePostCategoryAsAdminMutationHookResult = ReturnType<typeof useUpdatePostCategoryAsAdminMutation>;
export type UpdatePostCategoryAsAdminMutationResult = Apollo.MutationResult<UpdatePostCategoryAsAdminMutation>;
export type UpdatePostCategoryAsAdminMutationOptions = Apollo.BaseMutationOptions<UpdatePostCategoryAsAdminMutation, UpdatePostCategoryAsAdminMutationVariables>;
export const UpdatePostCategoryNameAsAdminDocument = gql`
    mutation UpdatePostCategoryNameAsAdmin($categoryNameInput: PostCategoryNameInput!) {
  updatePostCategoryNameAsAdmin(categoryNameInput: $categoryNameInput) {
    id
    postCategory {
      id
    }
    languageCode
    name
  }
}
    `;
export type UpdatePostCategoryNameAsAdminMutationFn = Apollo.MutationFunction<UpdatePostCategoryNameAsAdminMutation, UpdatePostCategoryNameAsAdminMutationVariables>;

/**
 * __useUpdatePostCategoryNameAsAdminMutation__
 *
 * To run a mutation, you first call `useUpdatePostCategoryNameAsAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePostCategoryNameAsAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePostCategoryNameAsAdminMutation, { data, loading, error }] = useUpdatePostCategoryNameAsAdminMutation({
 *   variables: {
 *      categoryNameInput: // value for 'categoryNameInput'
 *   },
 * });
 */
export function useUpdatePostCategoryNameAsAdminMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePostCategoryNameAsAdminMutation, UpdatePostCategoryNameAsAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePostCategoryNameAsAdminMutation, UpdatePostCategoryNameAsAdminMutationVariables>(UpdatePostCategoryNameAsAdminDocument, options);
      }
export type UpdatePostCategoryNameAsAdminMutationHookResult = ReturnType<typeof useUpdatePostCategoryNameAsAdminMutation>;
export type UpdatePostCategoryNameAsAdminMutationResult = Apollo.MutationResult<UpdatePostCategoryNameAsAdminMutation>;
export type UpdatePostCategoryNameAsAdminMutationOptions = Apollo.BaseMutationOptions<UpdatePostCategoryNameAsAdminMutation, UpdatePostCategoryNameAsAdminMutationVariables>;
export const GetPostFromsDocument = gql`
    query GetPostFroms {
  getPostFroms {
    id
    name
    thumbnail {
      id
      uri
    }
  }
}
    `;

/**
 * __useGetPostFromsQuery__
 *
 * To run a query within a React component, call `useGetPostFromsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPostFromsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPostFromsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPostFromsQuery(baseOptions?: Apollo.QueryHookOptions<GetPostFromsQuery, GetPostFromsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPostFromsQuery, GetPostFromsQueryVariables>(GetPostFromsDocument, options);
      }
export function useGetPostFromsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPostFromsQuery, GetPostFromsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPostFromsQuery, GetPostFromsQueryVariables>(GetPostFromsDocument, options);
        }
export function useGetPostFromsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetPostFromsQuery, GetPostFromsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPostFromsQuery, GetPostFromsQueryVariables>(GetPostFromsDocument, options);
        }
export type GetPostFromsQueryHookResult = ReturnType<typeof useGetPostFromsQuery>;
export type GetPostFromsLazyQueryHookResult = ReturnType<typeof useGetPostFromsLazyQuery>;
export type GetPostFromsSuspenseQueryHookResult = ReturnType<typeof useGetPostFromsSuspenseQuery>;
export type GetPostFromsQueryResult = Apollo.QueryResult<GetPostFromsQuery, GetPostFromsQueryVariables>;
export const UpdatePostFromAsAdminDocument = gql`
    mutation UpdatePostFromAsAdmin($postFromInput: PostFromInput!) {
  updatePostFromAsAdmin(postFromInput: $postFromInput) {
    id
    name
    thumbnail {
      id
      uri
    }
  }
}
    `;
export type UpdatePostFromAsAdminMutationFn = Apollo.MutationFunction<UpdatePostFromAsAdminMutation, UpdatePostFromAsAdminMutationVariables>;

/**
 * __useUpdatePostFromAsAdminMutation__
 *
 * To run a mutation, you first call `useUpdatePostFromAsAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePostFromAsAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePostFromAsAdminMutation, { data, loading, error }] = useUpdatePostFromAsAdminMutation({
 *   variables: {
 *      postFromInput: // value for 'postFromInput'
 *   },
 * });
 */
export function useUpdatePostFromAsAdminMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePostFromAsAdminMutation, UpdatePostFromAsAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePostFromAsAdminMutation, UpdatePostFromAsAdminMutationVariables>(UpdatePostFromAsAdminDocument, options);
      }
export type UpdatePostFromAsAdminMutationHookResult = ReturnType<typeof useUpdatePostFromAsAdminMutation>;
export type UpdatePostFromAsAdminMutationResult = Apollo.MutationResult<UpdatePostFromAsAdminMutation>;
export type UpdatePostFromAsAdminMutationOptions = Apollo.BaseMutationOptions<UpdatePostFromAsAdminMutation, UpdatePostFromAsAdminMutationVariables>;
export const GetUsersAsAdminDocument = gql`
    query GetUsersAsAdmin($first: Int, $after: String) {
  getUsersAsAdmin(first: $first, after: $after) {
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      id
      avatar {
        id
        uri
      }
      email
      name
      gender
      phoneCode
      phone
      dateOfBirth
      provider
      resignReason
      friendsCount
      createdAt
      updatedAt
      deletedAt
    }
  }
}
    `;

/**
 * __useGetUsersAsAdminQuery__
 *
 * To run a query within a React component, call `useGetUsersAsAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersAsAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersAsAdminQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGetUsersAsAdminQuery(baseOptions?: Apollo.QueryHookOptions<GetUsersAsAdminQuery, GetUsersAsAdminQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUsersAsAdminQuery, GetUsersAsAdminQueryVariables>(GetUsersAsAdminDocument, options);
      }
export function useGetUsersAsAdminLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersAsAdminQuery, GetUsersAsAdminQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUsersAsAdminQuery, GetUsersAsAdminQueryVariables>(GetUsersAsAdminDocument, options);
        }
export function useGetUsersAsAdminSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetUsersAsAdminQuery, GetUsersAsAdminQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUsersAsAdminQuery, GetUsersAsAdminQueryVariables>(GetUsersAsAdminDocument, options);
        }
export type GetUsersAsAdminQueryHookResult = ReturnType<typeof useGetUsersAsAdminQuery>;
export type GetUsersAsAdminLazyQueryHookResult = ReturnType<typeof useGetUsersAsAdminLazyQuery>;
export type GetUsersAsAdminSuspenseQueryHookResult = ReturnType<typeof useGetUsersAsAdminSuspenseQuery>;
export type GetUsersAsAdminQueryResult = Apollo.QueryResult<GetUsersAsAdminQuery, GetUsersAsAdminQueryVariables>;