import { useInView } from "react-intersection-observer";
import { useGetAdCompaniesQuery } from "../../../lib/apollo/graphql/generated";
import { useState } from "react";

function useAdsCompanies() {
  const { ref: fetchMoreRef } = useInView({
    threshold: 0.2,
    onChange: onEndReached,
  });

  const [fetchingMore, setFetchingMore] = useState(false);

  const [showNewRow, setShowNewRow] = useState(false);

  const { loading, data, fetchMore } = useGetAdCompaniesQuery({
    variables: {
      first: 20,
    },
  });

  function toggleShowNewRow(value: boolean) {
    setShowNewRow(value);
  }

  function onEndReached(inView: boolean) {
    if (
      inView &&
      !fetchingMore &&
      data?.getAdCompanies?.pageInfo?.hasNextPage
    ) {
      setFetchingMore(true);

      fetchMore({
        variables: {
          first: 20,
          after: data?.getAdCompanies?.pageInfo?.endCursor,
        },
      }).finally(() => {
        setFetchingMore(false);
      });
    }
  }

  return {
    refs: {
      fetchMoreRef,
    },
    models: {
      loading,
      data: data?.getAdCompanies?.edges,
      showNewRow,
    },
    operations: {
      toggleShowNewRow,
    },
  };
}

export default useAdsCompanies;
