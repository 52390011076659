import React from "react";
import useTabs from "../../common/hooks/useTabs";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import AdsCompanies from "../components/AdsCompanies";
import AdsList from "../components/AdsList";

function AdsContainer() {
  const {
    models: { tabIndex },
    operations: { onTabSelect },
  } = useTabs();

  return (
    <div>
      <Tabs tabIndex={tabIndex} onSelect={onTabSelect}>
        <TabList>
          <Tab>광고</Tab>
          <Tab>광고회사</Tab>
        </TabList>
        <TabPanel>
          <AdsList />
        </TabPanel>
        <TabPanel>
          <AdsCompanies />
        </TabPanel>
      </Tabs>
    </div>
  );
}

export default AdsContainer;
