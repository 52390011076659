import React from "react";
import {
  PolicyType,
  useGetPoliciesQuery,
} from "../../../lib/apollo/graphql/generated";
import Loader from "../../common/components/Loader";
import PolicyCard from "./PolicyCard";
import Flex from "../../common/components/Flex";
import Button from "../../common/components/Button";
import { useNavigate } from "react-router-dom";
import { SCREENS } from "../../../lib/route/utils/router";
import theme from "../../../lib/theme";

interface PolicyListProps {
  type: PolicyType;
}

function PolicyList({ type }: PolicyListProps) {
  const navigate = useNavigate();

  const { loading, data } = useGetPoliciesQuery({
    variables: {
      type,
    },
  });

  if (loading) return <Loader />;

  return (
    <div>
      <Flex justifyContent="flex-end" marginBottom={theme.spacing[24]}>
        <Button
          text="추가하기"
          onClick={() => navigate(SCREENS.POLICY + "/" + type)}
        />
      </Flex>
      {data?.getPolicies?.map((policy) => (
        <PolicyCard
          key={policy?.id}
          type={policy?.type!}
          body={policy?.body!}
          createdAt={policy?.createdAt}
        />
      ))}
    </div>
  );
}

export default PolicyList;
