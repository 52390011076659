import { ReactChildrenProp } from "global";
import React, { CSSProperties, memo } from "react";

interface GridProps {
  className?: string;
  onClick?: () => void;
}

function Grid({
  children,
  className = undefined,
  onClick,
  ...rest
}: GridProps & ReactChildrenProp & CSSProperties) {
  return (
    <div
      className={className}
      style={{ display: "grid", ...rest }}
      onClick={onClick}
    >
      {children}
    </div>
  );
}

export default memo(Grid);
