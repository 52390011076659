import React, { useState } from "react";
import Flex from "../../common/components/Flex";
import Text from "../../common/components/Text";
import { PolicyType } from "../../../lib/apollo/graphql/generated";
import dayjs from "dayjs";
import Button from "../../common/components/Button";
import theme from "../../../lib/theme";

interface PolicyCardProps {
  type: PolicyType;
  body: string;
  createdAt: string;
}

function PolicyCard({ type, body, createdAt }: PolicyCardProps) {
  const [show, setShow] = useState(false);

  return (
    <Flex
      flexDirection="column"
      width="100%"
      border={`1px solid ${theme.color.gray2}`}
      borderRadius={4}
      marginBottom={theme.spacing[8]}
      gap={theme.spacing[8]}
    >
      <Flex
        alignItems="center"
        justifyContent="space-between"
        padding={theme.spacing[12]}
      >
        <Text typography="16B">
          {dayjs(createdAt).format("YYYY-MM-DD")}에 작성된{" "}
          {type === PolicyType.Privacy ? "개인정보처리방침" : "이용약관"}
        </Text>
        <Button text={show ? "닫기" : "보기"} onClick={() => setShow(!show)} />
      </Flex>
      {show && (
        <div
          style={{
            borderTop: `1px solid ${theme.color.gray3}`,
            padding: theme.spacing[24],
          }}
          dangerouslySetInnerHTML={{ __html: body }}
        />
      )}
    </Flex>
  );
}

export default PolicyCard;
