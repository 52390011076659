import React from "react";
import useTabs from "../../common/hooks/useTabs";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import AnnouncementList from "../components/AnnouncementList";
import NoticeList from "../components/NoticeList";

function AnnouncementContainer() {
  const {
    models: { tabIndex },
    operations: { onTabSelect },
  } = useTabs();

  return (
    <div>
      <Tabs tabIndex={tabIndex} onSelect={onTabSelect}>
        <TabList>
          <Tab>공지사항</Tab>
          <Tab>배너관리</Tab>
        </TabList>
        <TabPanel>
          <AnnouncementList />
        </TabPanel>
        <TabPanel>
          <NoticeList />
        </TabPanel>
      </Tabs>
    </div>
  );
}

export default AnnouncementContainer;
