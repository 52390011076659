import React from "react";
import Loader from "../../common/components/Loader";
import usePostCategories from "./PostCategories.hook";
import Grid from "../../common/components/Grid";
import theme from "../../../lib/theme";
import OptionCard from "../../common/components/OptionCard";

function PostCategories() {
  const {
    models: { loading, data, updateLoading },
    operations: { onUpdate, onNameUpdate },
  } = usePostCategories();

  if (loading) return <Loader fullScreen />;

  return (
    <Grid
      width="100%"
      gridTemplateColumns="repeat(4,1fr)"
      gap={theme.spacing[8]}
    >
      <OptionCard
        item={null}
        updateLoading={updateLoading}
        onUpdate={onUpdate}
        onNameUpdate={onNameUpdate}
      />
      {data?.map((category) => (
        <OptionCard
          key={category?.id}
          item={category}
          updateLoading={updateLoading}
          onUpdate={onUpdate}
          onNameUpdate={onNameUpdate}
        />
      ))}
    </Grid>
  );
}

export default PostCategories;
