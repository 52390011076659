import React from "react";
import useUsersContainer from "./UsersContainer.hook";
import Loader from "../../../common/components/Loader";
import Table from "../../../common/components/Table";
import TableCell from "../../../common/components/TableCell";
import FetchMore from "../../../common/components/FetchMore";
import dayjs from "dayjs";

function UsersContainer() {
  const {
    refs: { fetchMoreRef },
    models: { loading, data },
  } = useUsersContainer();

  if (loading) return <Loader />;

  return (
    <div>
      <Table
        columns={[
          {
            label: "ID",
            width: "10%",
          },
          {
            label: "Name",
            width: "15%",
          },
          {
            label: "Email",
            width: "15%",
          },
          {
            label: "Avatar",
            width: "20%",
          },
          {
            label: "Friends Count",
            width: "5%",
          },
          {
            label: "Resign reason",
            width: "10%",
          },
          {
            label: "Created At",
          },
          {
            label: "Deleted At",
          },
        ]}
        body={
          <>
            {data?.map((user) => {
              const {
                id,
                avatar,
                name,
                email,
                phone,
                friendsCount,
                resignReason,
                createdAt,
                deletedAt,
              } = user!;
              return (
                <tr key={user?.id} style={{ borderBottom: "1px solid grey" }}>
                  <TableCell>{id}</TableCell>
                  <TableCell>{name}</TableCell>
                  <TableCell>{email}</TableCell>
                  <TableCell>
                    <img
                      src={avatar?.uri || ""}
                      style={{ height: 150, objectFit: "contain" }}
                    />
                  </TableCell>
                  <TableCell>{friendsCount}</TableCell>
                  <TableCell>{resignReason || ""}</TableCell>
                  <TableCell>{dayjs(createdAt).format("YYYY-MM-DD")}</TableCell>
                  <TableCell>
                    {!!deletedAt ? dayjs(deletedAt).format("YYYY-MM-DD") : "X"}
                  </TableCell>
                </tr>
              );
            })}
          </>
        }
      />
      <FetchMore fetchMoreRef={fetchMoreRef} />
    </div>
  );
}

export default UsersContainer;
