import React, { useState } from "react";
import TableCell from "../../common/components/TableCell";
import Input from "../../common/components/Input";
import Button from "../../common/components/Button";
import Flex from "../../common/components/Flex";
import theme from "../../../lib/theme";
import {
  GetPostFromsDocument,
  PostFromInput,
  useUpdatePostFromAsAdminMutation,
} from "../../../lib/apollo/graphql/generated";
import imageCompression from "browser-image-compression";
import { IMAGE_COMPRESSION_OPTIONS } from "../../../utils/constants";

interface PostFromRowProps {
  item?: {
    id?: string | null;
    name?: string | null;
    thumbnail?: string | null;
  } | null;
}

type State = {
  id: string;
  name: string;
  thumbnailState: any;
};

function PostFromRow({ item }: PostFromRowProps) {
  const [state, setState] = useState<State>({
    id: item?.id || "",
    name: item?.name || "",
    thumbnailState: null,
  });

  const [updatePostFromAsAdmin, { loading }] = useUpdatePostFromAsAdminMutation(
    {
      onCompleted: (data) => {
        if (data?.updatePostFromAsAdmin) {
          setState({
            id: item?.id || "",
            name: item?.name || "",
            thumbnailState: null,
          });
        }
      },
      refetchQueries: !item
        ? () => [
            {
              query: GetPostFromsDocument,
            },
          ]
        : undefined,
    }
  );

  function onInputChange(e: React.ChangeEvent<HTMLInputElement>, key: string) {
    const { value } = e.target;

    setState((prev) => ({
      ...prev,
      [key]: value,
    }));
  }

  function onFileChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { files } = e.target;

    setState((prev) => ({
      ...prev,
      thumbnailState: files?.[0],
    }));
  }

  async function onSubmit() {
    const { id, name, thumbnailState } = state;

    const postFromInput: PostFromInput = {
      id,
      name,
    };

    if (thumbnailState) {
      const compressedFile = await imageCompression(
        thumbnailState,
        IMAGE_COMPRESSION_OPTIONS
      );

      postFromInput.thumbnailInput = { file: compressedFile };
    }

    updatePostFromAsAdmin({
      variables: {
        postFromInput,
      },
    });
  }

  return (
    <tr>
      <TableCell>
        <Input value={state?.id} disabled />
      </TableCell>
      <TableCell>
        <Input
          value={state?.name}
          placeholder="출처 이름"
          onChange={(e) => onInputChange(e, "name")}
        />
      </TableCell>
      <TableCell>
        <Flex alignItems="center" gap={theme.spacing[4]}>
          {(state?.thumbnailState || item?.thumbnail) && (
            <img
              src={
                state?.thumbnailState
                  ? URL.createObjectURL(state.thumbnailState)
                  : item?.thumbnail!
              }
              style={{ height: 50, objectFit: "contain" }}
            />
          )}
          <Input type="file" accept="image/*" onChange={onFileChange} />
        </Flex>
      </TableCell>
      <TableCell>
        <Flex alignItems="center" gap={theme.spacing[4]}>
          <Button size="sm" text="수정" disabled={loading} onClick={onSubmit} />
        </Flex>
      </TableCell>
    </tr>
  );
}

export default PostFromRow;
