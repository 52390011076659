import { ReactChildrenProp } from "global";
import React from "react";
import theme from "../../../lib/theme";

function TableCell({ children }: ReactChildrenProp) {
  return (
    <td
      style={{
        padding: `${theme.spacing[4]}px ${theme.spacing[8]}px`,
        overflow: "hidden",
      }}
    >
      {children}
    </td>
  );
}

export default TableCell;
