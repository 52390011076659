import {
  GetPostCategoriesDocument,
  useGetPostCategoriesQuery,
  useUpdatePostCategoryAsAdminMutation,
  useUpdatePostCategoryNameAsAdminMutation,
} from "../../../lib/apollo/graphql/generated";

function usePostCategories() {
  const { loading, data } = useGetPostCategoriesQuery();

  const [updatePostCategoryAsAdmin, { loading: updateLoading }] =
    useUpdatePostCategoryAsAdminMutation({
      refetchQueries,
    });

  const [updatePostCategorynameAsAdmin, { loading: updateNameLoading }] =
    useUpdatePostCategoryNameAsAdminMutation({
      refetchQueries,
    });

  function refetchQueries() {
    return [{ query: GetPostCategoriesDocument }];
  }

  function onUpdate({
    id,
    order,
    active,
  }: {
    id: string | null;
    order: number;
    active: boolean;
  }) {
    updatePostCategoryAsAdmin({
      variables: {
        categoryInput: {
          id,
          order: Number(order),
          active,
        },
      },
    });
  }

  function onNameUpdate({
    id,
    languageCode,
    name,
    parentId,
  }: {
    id: string | null;
    languageCode: string;
    name: string;
    parentId: string;
  }) {
    updatePostCategorynameAsAdmin({
      variables: {
        categoryNameInput: {
          id,
          name,
          languageCode,
          postCategoryId: parentId,
        },
      },
    });
  }

  return {
    models: {
      loading,
      updateLoading: updateLoading || updateNameLoading,
      data: data?.getPostCategories,
    },
    operations: {
      onUpdate,
      onNameUpdate,
    },
  };
}

export default usePostCategories;
