import React from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import GiftCategories from "../components/GiftCategories";
import GiftPurposes from "../components/GiftPurposes";
import useTabs from "../../common/hooks/useTabs";

function GiftOptionsContainer() {
  const {
    models: { tabIndex },
    operations: { onTabSelect },
  } = useTabs();

  return (
    <div>
      <Tabs tabIndex={tabIndex} onSelect={onTabSelect}>
        <TabList>
          <Tab>카테고리</Tab>
          <Tab>목적</Tab>
        </TabList>
        <TabPanel>
          <GiftCategories />
        </TabPanel>
        <TabPanel>
          <GiftPurposes />
        </TabPanel>
      </Tabs>
    </div>
  );
}

export default GiftOptionsContainer;
