import { ReactChildrenProp } from "global";
import React, { useEffect, useState } from "react";
import Flex from "../../../modules/common/components/Flex";
import SideBar from "./SideBar";
import { useLocation } from "react-router-dom";
import { SIDE_MENU } from "../utils/router";
import { useFirebaseContext } from "../../../modules/common/providers/FirebaseProvider";
import theme from "../../theme";
import Text from "../../../modules/common/components/Text";
import Button from "../../../modules/common/components/Button";
import { firebaseSignOut } from "../../firebase";

interface RouteWrapperProps extends ReactChildrenProp {}

function RouteWrapper({ children }: RouteWrapperProps) {
  const user = useFirebaseContext();

  const location = useLocation();

  const [pathname, setPathname] = useState("");

  function getPathName() {
    for (let key of Object.keys(SIDE_MENU)) {
      if (key === location.pathname) {
        setPathname(SIDE_MENU[key]?.name);
        break;
      }
    }
  }

  useEffect(() => {
    getPathName();
  }, [location?.pathname]);

  return (
    <Flex width="100vw" height="100vh" alignItems="flex-start">
      <SideBar />
      <Flex flex={1} flexDirection="column" height="100vh">
        <Flex
          height={50}
          width="100%"
          padding={`${theme.spacing[8]}px ${theme.spacing[4]}px`}
          alignItems="center"
          justifyContent="space-between"
          backgroundColor="rgba(0,0,0,0.2)"
        >
          <Text typography="24B">{pathname}</Text>
          <Flex alignItems="center" gap={theme.spacing[8]}>
            <Text typography="12">{user?.email}</Text>
            <Button
              text="SIGN OUT"
              size="xs"
              bgColor="black"
              fontColor="white"
              onClick={firebaseSignOut}
            />
          </Flex>
        </Flex>
        <Flex
          flexDirection="column"
          flex={1}
          position="relative"
          height="calc(100vh - 50px)"
          padding={theme.spacing[12]}
          overflow="auto"
          paddingBottom={theme.spacing[24]}
        >
          {children}
        </Flex>
      </Flex>
    </Flex>
  );
}

export default RouteWrapper;
