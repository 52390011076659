import React, { useEffect, useState } from "react";
import imageCompression from "browser-image-compression";
import {
  GetAllNoticesAsAdminDocument,
  useUpdateNoticeAsAdminMutation,
} from "../../../lib/apollo/graphql/generated";
import Flex from "../../common/components/Flex";
import theme from "../../../lib/theme";
import Input from "../../common/components/Input";
import Button from "../../common/components/Button";
import { IMAGE_COMPRESSION_OPTIONS } from "../../../utils/constants";
import { uploadToFirebaseStorage } from "../../../lib/firebase";

interface NoticeCardProps {
  notice?: {
    __typename?: "Notice";
    id: string;
    imageUrl?: string | null;
    externalUrl?: string | null;
    active: boolean;
    createdAt: any;
    updatedAt: any;
    announcement?: {
      __typename?: "Announcement";
      id: string;
      title: string;
    } | null;
  } | null;

  onCancel?: () => void;
}

function NoticeCard({ notice, onCancel }: NoticeCardProps) {
  const [state, setState] = useState({
    id: notice?.id || "",
    announcementId: notice?.announcement?.id || "",
    imageUrl: notice?.imageUrl || "",
    externalUrl: notice?.externalUrl || "",
    active: notice?.active || true,
  });

  const [mediaState, setMediaState] = useState<File | null>(null);

  const [updateNoticeAsAdmin, { loading }] = useUpdateNoticeAsAdminMutation({
    onCompleted: (data) => {
      if (data?.updateNoticeAsAdmin) {
        setState({
          id: notice?.id || "",
          announcementId: notice?.announcement?.id || "",
          imageUrl: notice?.imageUrl || "",
          externalUrl: notice?.externalUrl || "",
          active: notice?.active || true,
        });

        onCancel && onCancel();
      }
    },

    refetchQueries: !notice
      ? () => [
          {
            query: GetAllNoticesAsAdminDocument,
            variables: {
              first: 10,
            },
          },
        ]
      : undefined,
  });

  useEffect(() => {
    if (notice) {
      setState({
        id: notice?.id || "",
        announcementId: notice?.announcement?.id || "",
        imageUrl: notice?.imageUrl || "",
        externalUrl: notice?.externalUrl || "",
        active: notice?.active || true,
      });
    }
  }, [notice]);

  function onInputChange(e: React.ChangeEvent<HTMLInputElement>, key: string) {
    const { value, checked } = e.target;
    setState((prev) => {
      return {
        ...prev,
        [key]: key === "active" ? checked : value,
      };
    });
  }

  function onImageChange(e: React.ChangeEvent<HTMLInputElement>) {
    const file = e.target.files?.[0];
    if (file) {
      setMediaState(file);
    }
  }

  async function onSubmit() {
    const { id, announcementId, imageUrl, externalUrl, active } = state;

    let image = imageUrl;

    if (mediaState) {
      const compressedFile = await imageCompression(
        mediaState,
        IMAGE_COMPRESSION_OPTIONS
      );
      const { name, size, type } = compressedFile;

      const metadata = { name, size, type };
      const url = await uploadToFirebaseStorage(
        compressedFile,
        metadata,
        "public"
      );

      image = url;
    }

    updateNoticeAsAdmin({
      variables: {
        noticeInput: {
          id,
          announcementId,
          imageUrl: image,
          externalUrl,
          active,
        },
      },
    });
  }

  const { id, announcementId, imageUrl, externalUrl, active } = state;

  return (
    <Flex
      flexDirection="column"
      width="100%"
      gap={theme.spacing[12]}
      borderRadius={12}
      border={`1px solid ${theme.color.gray3}`}
      padding={theme.spacing[12]}
    >
      <Input label="ID" value={id} disabled />
      <Input
        label="Announcement ID"
        value={announcementId}
        onChange={(e) => onInputChange(e, "announcementId")}
      />
      <Flex flexDirection="column" gap={theme.spacing[4]}>
        <Input
          label="Image"
          type="file"
          accept="image/*"
          onChange={onImageChange}
        />
        {(mediaState || imageUrl) && (
          <img
            src={mediaState ? URL.createObjectURL(mediaState) : imageUrl}
            style={{ width: "50%", aspectRatio: 4 / 3, objectFit: "cover" }}
          />
        )}
      </Flex>
      <Input
        label="External URL"
        value={externalUrl}
        onChange={(e) => onInputChange(e, "externalUrl")}
      />
      <Input
        label="Active"
        type="checkbox"
        checked={active}
        onChange={(e) => onInputChange(e, "active")}
      />
      <Button
        text="Submit"
        disabled={(!announcementId && !externalUrl) || loading}
        onClick={onSubmit}
      />
    </Flex>
  );
}

export default NoticeCard;
