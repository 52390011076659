import imageCompression from "browser-image-compression";
import { useEffect, useRef } from "react";
import ReactQuill from "react-quill";
import { uploadToFirebaseStorage } from "../../../lib/firebase";
import { IMAGE_COMPRESSION_OPTIONS } from "../../../utils/constants";

function useEditor(mediaFolder: string = "content") {
  const editorRef = useRef<ReactQuill>(null);

  useEffect(() => {
    /**
     * When user upload an image from quill editor
     * It gets file from html input
     * and upload it to firebase storage
     * and get the url for the image
     */
    function imageUploader() {
      const input = document.createElement("input");

      input.setAttribute("type", "file");
      input.setAttribute("accept", "image/*");
      input.click();

      input.onchange = async () => {
        const file = input.files![0];

        const compressedFile = await imageCompression(
          file,
          IMAGE_COMPRESSION_OPTIONS
        );
        const { name, size, type } = compressedFile;

        const metadata = { name, size, type };
        const url = await uploadToFirebaseStorage(
          compressedFile,
          metadata,
          mediaFolder
        );

        if (url && editorRef?.current) {
          const range = editorRef?.current?.getEditor().getSelection();

          editorRef?.current
            ?.getEditor()
            .insertEmbed(range?.index!, "image", url);
        }
      };
    }

    if (editorRef?.current) {
      const toolbar = editorRef?.current.getEditor().getModule("toolbar");
      toolbar.addHandler("image", imageUploader);
    }
  }, [editorRef?.current]);

  return {
    refs: {
      editorRef,
    },
  };
}

export default useEditor;
