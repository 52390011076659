import React from "react";
import Flex from "../../common/components/Flex";
import theme from "../../../lib/theme";
import useOptionCard from "./OptionCard.hook";
import Input from "../../common/components/Input";
import Button from "../../common/components/Button";
import { LANGUAGE_CODES } from "../../../utils/constants";
import Text from "../../common/components/Text";

export interface OptionCardProps {
  item?: {
    id: string;
    active: boolean;
    order: number;
    names?: Array<{
      // __typename?: "CategoryName" | "PurposeName";
      id: string;
      languageCode: string;
      name: string;
    } | null> | null;
  } | null;
  updateLoading?: boolean;
  onUpdate: ({
    id,
    order,
    active,
  }: {
    id: string | null;
    order: number;
    active: boolean;
  }) => void;
  onNameUpdate: ({
    id,
    languageCode,
    name,
    parentId,
  }: {
    id: string | null;
    languageCode: string;
    name: string;
    parentId: string;
  }) => void;
}

function OptionCard({
  item,
  updateLoading,
  onUpdate,
  onNameUpdate,
}: OptionCardProps) {
  const {
    models: { state, languageCode, showLanguageCode },
    operations: {
      onInputChange,
      onNameChange,
      toggleShowLanguageCode,
      onLanguageCodeSelect,
      onNameAdd,
      onAfterUpdate,
    },
  } = useOptionCard({ item });

  const { id, names, active, order } = state;

  return (
    <Flex
      flexDirection="column"
      padding={`${theme.spacing[12]}px ${theme.spacing[16]}px`}
      borderRadius={6}
      border={`1px solid ${theme.color.gray1}`}
      gap={theme.spacing[12]}
      alignItems="center"
    >
      {!!id && <Input label="ID" value={id} disabled />}
      <Input
        label="Active"
        type="checkbox"
        checked={active}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          onInputChange(e, "active")
        }
      />
      <Input
        label="Order"
        type="number"
        inputMode="numeric"
        value={order}
        onChange={(e) => onInputChange(e, "order")}
      />
      <Button
        text={updateLoading ? "업뎃중" : id ? "옵션 수정" : "옵션 추가"}
        disabled={updateLoading}
        onClick={() => {
          onUpdate({
            id,
            order: Number(order),
            active,
          });

          onAfterUpdate();
        }}
      />
      {!!id && showLanguageCode ? (
        <Flex alignItems="center" width="100%" gap={theme.spacing[12]}>
          <select
            style={{ flex: 1 }}
            value={languageCode}
            onChange={onLanguageCodeSelect}
          >
            <option value="">선택</option>
            {LANGUAGE_CODES.map((code) => (
              <option key={code} value={code}>
                {code}
              </option>
            ))}
          </select>
          <Button text="추가" onClick={onNameAdd} />
          <Button text="취소" onClick={toggleShowLanguageCode} />
        </Flex>
      ) : (
        <Button text="언어 추가" onClick={toggleShowLanguageCode} />
      )}
      {!!id &&
        Object.keys(names)?.map((code) => {
          const { id, languageCode, name } = names[code];
          return (
            <Flex
              width="100%"
              key={code}
              border={`1px solid ${theme.color.gray3}`}
              flexDirection="column"
              gap={theme.spacing[4]}
              padding={theme.spacing[8]}
            >
              <Text typography="12B">LANGUAGE : {languageCode}</Text>
              {!!id && <Input label="ID" value={id} disabled />}
              <Input
                label="Name"
                value={name}
                onChange={(e) => onNameChange(e, "name", code)}
              />
              <Button
                text={updateLoading ? "업뎃중" : "수정"}
                disabled={updateLoading || !name}
                onClick={() => {
                  onNameUpdate({
                    id: id || null,
                    languageCode: code,
                    name: name!,
                    parentId: state?.id,
                  });
                }}
              />
            </Flex>
          );
        })}
    </Flex>
  );
}

export default OptionCard;
