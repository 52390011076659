import React, { ComponentPropsWithRef, memo } from "react";
import Flex from "./Flex";
import InputLabel from "./InputLabel";
import Text from "./Text";
import theme from "../../../lib/theme";
import { Typography } from "theme";

interface InputProps extends ComponentPropsWithRef<"input"> {
  label?: string | number;
  labelTypography?: keyof Typography;
  errorMessage?: string;
  rightIcon?: string;
  onIconPress?: () => void;
}

function Input({
  label,
  labelTypography,
  errorMessage,
  rightIcon,
  onIconPress,
  ...rest
}: InputProps) {
  return (
    <Flex
      flexDirection="column"
      width="100%"
      alignItems="flex-start"
      gap={theme.spacing[4]}
    >
      {!!label && <InputLabel label={label} typography={labelTypography} />}
      <Flex
        width="100%"
        alignItems="center"
        padding={`${theme.spacing[12]}px ${theme.spacing[16]}px`}
        border={`1px solid ${
          !!errorMessage ? theme.color.error : theme.color.gray4
        }`}
        borderRadius={4}
        backgroundColor={theme.color.white}
      >
        <input
          style={{
            flex: 1,
            border: "none",
            ...theme.typography[14],
            lineHeight: 1.25,
          }}
          {...rest}
        />
        {!!rightIcon && (
          <Flex
            alignItems="center"
            justifyContent="center"
            cursor="pointer"
            onClick={onIconPress}
          >
            <img src={rightIcon} style={{ width: 16, height: 16 }} />
          </Flex>
        )}
      </Flex>
      {!!errorMessage && (
        <Text typography="12" color={theme.color.error}>
          {errorMessage}
        </Text>
      )}
    </Flex>
  );
}

export default memo(Input);
