import { Theme } from "theme";

const theme: Theme = {
  appMaxWidth: 1000,
  typography: {
    "48B": {
      fontSize: 48,
      lineHeight: "58px",
      fontWeight: "bold",
    },
    "40B": {
      fontSize: 40,
      lineHeight: "48px",
      fontWeight: "bold",
    },
    "32B": {
      fontSize: 32,
      lineHeight: "38px",
      fontWeight: "bold",
    },
    "28B": {
      fontSize: 28,
      lineHeight: "38px",
      fontWeight: "bold",
    },
    "24B": {
      fontSize: 24,
      lineHeight: "34px",
      fontWeight: "bold",
    },
    "20B": {
      fontSize: 20,
      lineHeight: "26px",
      fontWeight: "bold",
    },
    "20": {
      fontSize: 20,
      lineHeight: "26px",
      fontWeight: "normal",
    },
    "16B": {
      fontSize: 16,
      lineHeight: "20px",
      fontWeight: "bold",
    },
    "16": {
      fontSize: 16,
      lineHeight: "20px",
      fontWeight: "normal",
    },
    "14B": {
      fontSize: 14,
      lineHeight: "21px",
      fontWeight: "bold",
    },
    "14": {
      fontSize: 14,
      lineHeight: "21px",
      fontWeight: "normal",
    },
    "12B": {
      fontSize: 12,
      lineHeight: "14px",
      fontWeight: "bold",
    },
    "12": {
      fontSize: 12,
      lineHeight: "14px",
      fontWeight: "normal",
    },
    "11B": {
      fontSize: 11,
      lineHeight: "14px",
      fontWeight: "bold",
    },
    "11": {
      fontSize: 11,
      lineHeight: "14px",
      fontWeight: "normal",
    },
  },
  color: {
    success: "#37f78f",
    point: "#ecff9f",
    background: "#efeff4",
    background50: "#f7f7fa",
    tableHeader: "#ececf4",
    black: "#181818",
    white: "#ffffff",
    gray1: "#686868",
    gray2: "#8b8b8b",
    gray3: "#aeaeae",
    gray4: "#d1d1d1",
    primary: "#fbbc05",
    error: "#ff5033",
  },
  spacing: {
    0: 0,
    2: 2,
    4: 4,
    8: 8,
    10: 10,
    12: 12,
    16: 16,
    20: 20,
    24: 24,
    32: 32,
    40: 40,
    48: 48,
    60: 60,
    64: 64,
    80: 80,
  },
};

export default theme;
