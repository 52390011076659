import React from "react";
import Flex from "../../../modules/common/components/Flex";
import theme from "../../theme";
import { SCREENS, SIDE_MENU } from "../utils/router";
import { Link, useLocation } from "react-router-dom";
import Text from "../../../modules/common/components/Text";
import Button from "../../../modules/common/components/Button";

function SideBar() {
  const location = useLocation();

  return (
    <Flex
      flexDirection="column"
      minWidth={200}
      width="10%"
      height="100%"
      backgroundColor={theme.color.gray2}
      color={theme.color.black}
      overflow="auto"
    >
      {Object.keys(SIDE_MENU).map((key) => {
        const { name } = SIDE_MENU[key];
        const active = location?.pathname?.includes(key);

        if (key === SCREENS.LANDING) {
          return (
            <Link to={key} key={key}>
              <Flex
                padding={`${theme.spacing[8]}px ${theme.spacing[4]}px`}
                backgroundColor={theme.color.background}
                alignItems="center"
                justifyContent="center"
              >
                <Text typography="24B">KEEIFT</Text>
              </Flex>
            </Link>
          );
        }

        return (
          <Link to={key} key={key}>
            <Flex padding={`${theme.spacing[4]}px ${theme.spacing[8]}px`}>
              <Button
                fullWidth
                text={name}
                variant={active ? "contained" : "text"}
                bgColor={active ? "white" : "transparent"}
              />
            </Flex>
          </Link>
        );
      })}
    </Flex>
  );
}

export default SideBar;
