import { useGetPostFromsQuery } from "../../../lib/apollo/graphql/generated";

function usePostFroms() {
  const { loading, data } = useGetPostFromsQuery();

  return {
    models: {
      loading,
      data: data?.getPostFroms,
    },
  };
}

export default usePostFroms;
