import React from "react";
import loaderIcon from "../../../assets/icons/loading.svg";
import Flex from "./Flex";
import theme from "../../../lib/theme";

interface LoaderProps {
  fullScreen?: boolean;
}

function Loader({ fullScreen = false }: LoaderProps) {
  return (
    <Flex padding={theme.spacing[24]} flex={fullScreen ? 1 : 0}>
      <img
        src={loaderIcon}
        style={{ width: fullScreen ? 60 : 24, height: fullScreen ? 60 : 24 }}
      />
    </Flex>
  );
}

export default Loader;
