import React from "react";
import theme from "../../../lib/theme";

interface TableProps {
  columns: {
    label?: string | number;
    width?: string | number;
    onClick?: () => void;
  }[];
  body?: React.ReactNode;
}

function Table({ columns, body }: TableProps) {
  return (
    <table
      style={{
        width: "100%",
        borderCollapse: "collapse",
        tableLayout: "fixed",
      }}
    >
      <thead style={{ backgroundColor: "rgba(0,0,0,0.3)" }}>
        <tr>
          {columns?.map((column) => {
            const { label, width, onClick } = column;

            return (
              <th
                key={label}
                style={{ width: width || undefined, padding: theme.spacing[8] }}
                onClick={onClick}
              >
                {label}
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody>{body}</tbody>
    </table>
  );
}

export default Table;
