import React from "react";
import usePostList from "./PostList.hook";
import Loader from "../../common/components/Loader";
import Button from "../../common/components/Button";
import Table from "../../common/components/Table";
import Flex from "../../common/components/Flex";
import AddPost from "./AddPost";
import TableCell from "../../common/components/TableCell";
import dayjs from "dayjs";
import FetchMore from "../../common/components/FetchMore";

function PostList() {
  const {
    refs: { fetchMoreRef },
    models: { loading, data },
  } = usePostList();

  if (loading) return <Loader />;

  return (
    <div>
      <AddPost />
      <Table
        columns={[
          {
            label: "ID",
            width: "5%",
          },
          {
            label: "Category",
            width: "5%",
          },
          {
            label: "From",
            width: "5%",
          },
          {
            label: "Url",
            width: "10%",
          },
          {
            label: "Title",
            width: "20%",
          },
          {
            label: "Thumbnail",
            width: "20%",
          },
          {
            label: "Summary",
            width: "20%",
          },
          {
            label: "Created At",
          },
        ]}
        body={
          <>
            {data?.map((post) => {
              const {
                id,
                postCategory,
                postFrom,
                url,
                title,
                summary,
                thumbnailUri,
                createdAt,
              } = post!;
              return (
                <tr key={post?.id}>
                  <TableCell>{id}</TableCell>
                  <TableCell>{postCategory?.names?.[0]?.name}</TableCell>
                  <TableCell>{postFrom?.name}</TableCell>
                  <TableCell>
                    <a
                      href={url}
                      target="_blank"
                      style={{
                        textDecoration: "underline",
                      }}
                    >
                      링크보기
                    </a>
                  </TableCell>
                  <TableCell>{title}</TableCell>
                  <TableCell>
                    <img
                      src={thumbnailUri || ""}
                      style={{ height: 150, objectFit: "contain" }}
                    />
                  </TableCell>
                  <TableCell>{summary}</TableCell>
                  <TableCell>{dayjs(createdAt).format("YYYY-MM-DD")}</TableCell>
                </tr>
              );
            })}
          </>
        }
      />
      <FetchMore fetchMoreRef={fetchMoreRef} />
    </div>
  );
}

export default PostList;
