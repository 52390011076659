import React from "react";
import Grid from "../../common/components/Grid";
import theme from "../../../lib/theme";
import Loader from "../../common/components/Loader";
import useGiftPurposes from "./GiftPurposes.hook";
import OptionCard from "../../common/components/OptionCard";

function GiftPurposes() {
  const {
    models: { loading, data, updateLoading },
    operations: { onUpdate, onNameUpdate },
  } = useGiftPurposes();

  if (loading) return <Loader fullScreen />;

  return (
    <Grid
      width="100%"
      gridTemplateColumns="repeat(4,1fr)"
      gap={theme.spacing[8]}
    >
      <OptionCard
        item={null}
        updateLoading={updateLoading}
        onUpdate={onUpdate}
        onNameUpdate={onNameUpdate}
      />
      {data?.map((purpose) => (
        <OptionCard
          key={purpose?.id}
          item={purpose}
          updateLoading={updateLoading}
          onUpdate={onUpdate}
          onNameUpdate={onNameUpdate}
        />
      ))}
    </Grid>
  );
}

export default GiftPurposes;
