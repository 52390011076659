import {
  GetPurposesDocument,
  useGetPurposesQuery,
  useUpdatePurposeAsAdminMutation,
  useUpdatePurposeNameAsAdminMutation,
} from "../../../lib/apollo/graphql/generated";

function useGiftPurposes() {
  const { loading, data } = useGetPurposesQuery();

  const [updatePurposeAsAdmin, { loading: updateLoading }] =
    useUpdatePurposeAsAdminMutation({
      refetchQueries,
    });
  const [updatePurposeNameAsAdmin, { loading: updateNameLoading }] =
    useUpdatePurposeNameAsAdminMutation({ refetchQueries });

  function refetchQueries() {
    return [{ query: GetPurposesDocument }];
  }

  function onUpdate({
    id,
    order,
    active,
  }: {
    id: string | null;
    order: number;
    active: boolean;
  }) {
    updatePurposeAsAdmin({
      variables: {
        purposeInput: {
          id,
          order: Number(order),
          active,
        },
      },
    });
  }

  function onNameUpdate({
    id,
    languageCode,
    name,
    parentId,
  }: {
    id: string | null;
    languageCode: string;
    name: string;
    parentId: string;
  }) {
    updatePurposeNameAsAdmin({
      variables: {
        purposeNameInput: {
          id,
          name,
          languageCode,
          purposeId: parentId,
        },
      },
    });
  }

  return {
    models: {
      loading,
      updateLoading: updateLoading || updateNameLoading,
      data: data?.getPurposes,
    },
    operations: {
      onUpdate,
      onNameUpdate,
    },
  };
}

export default useGiftPurposes;
