import React, { useEffect, useState } from "react";
import TableCell from "../../common/components/TableCell";
import {
  GetAdCompaniesDocument,
  useUpdateAdCompanyAsAdminMutation,
} from "../../../lib/apollo/graphql/generated";
import Input from "../../common/components/Input";
import dayjs from "dayjs";
import Flex from "../../common/components/Flex";
import theme from "../../../lib/theme";
import Button from "../../common/components/Button";

interface AdsCompanyRowProps {
  company?: {
    __typename?: "AdCompany";
    id: string;
    name: string;
    ceo: string;
    registrationNumber?: string | null;
    url?: string | null;
    phone?: string | null;
    manager?: string | null;
    managerPhone?: string | null;
    adsCount: number;
    createdAt: any;
    updatedAt: any;
  } | null;
  onCancel?: () => void;
}

function AdsCompanyRow({ company, onCancel }: AdsCompanyRowProps) {
  const [state, setState] = useState({
    id: company?.id || "",
    name: company?.name || "",
    ceo: company?.ceo || "",
    registrationNumber: company?.registrationNumber || "",
    url: company?.url || "",
    phone: company?.phone || "",
    manager: company?.manager || "",
    managerPhone: company?.managerPhone || "",
  });

  const [updateAdCompanyAsAdmin, { loading }] =
    useUpdateAdCompanyAsAdminMutation({
      onCompleted: (data) => {
        if (data?.updateAdCompanyAsAdmin) {
          setState({
            id: company?.id || "",
            name: company?.name || "",
            ceo: company?.ceo || "",
            registrationNumber: company?.registrationNumber || "",
            url: company?.url || "",
            phone: company?.phone || "",
            manager: company?.manager || "",
            managerPhone: company?.managerPhone || "",
          });

          onCancel && onCancel();
        }
      },
      refetchQueries: !company
        ? () => [
            {
              query: GetAdCompaniesDocument,
              variables: {
                first: 20,
              },
            },
          ]
        : undefined,
    });

  useEffect(() => {
    if (company) {
      setState({
        id: company?.id || "",
        name: company?.name || "",
        ceo: company?.ceo || "",
        registrationNumber: company?.registrationNumber || "",
        url: company?.url || "",
        phone: company?.phone || "",
        manager: company?.manager || "",
        managerPhone: company?.managerPhone || "",
      });
    }
  }, [company]);

  function onInputChange(e: React.ChangeEvent<HTMLInputElement>, key: string) {
    const { value } = e.target;
    setState((prev) => {
      return {
        ...prev,
        [key]: value,
      };
    });
  }

  function onSubmit() {
    const {
      id,
      name,
      ceo,
      registrationNumber,
      url,
      phone,
      manager,
      managerPhone,
    } = state;

    updateAdCompanyAsAdmin({
      variables: {
        adCompanyInput: {
          id,
          name,
          ceo,
          registrationNumber,
          url,
          phone,
          manager,
          managerPhone,
        },
      },
    });
  }

  const {
    id,
    name,
    ceo,
    registrationNumber,
    url,
    phone,
    manager,
    managerPhone,
  } = state;

  return (
    <tr key={id}>
      <TableCell>{id}</TableCell>
      <TableCell>
        <div style={{ width: "100%" }}>
          <Input
            placeholder="name"
            value={name}
            onChange={(e) => onInputChange(e, "name")}
          />
        </div>
      </TableCell>
      <TableCell>
        <Input
          placeholder="ceo"
          value={ceo}
          onChange={(e) => onInputChange(e, "ceo")}
        />
      </TableCell>
      <TableCell>
        <Input
          placeholder="registrationNumber"
          value={registrationNumber}
          onChange={(e) => onInputChange(e, "registrationNumber")}
        />
      </TableCell>
      <TableCell>
        <Input
          placeholder="url"
          value={url}
          onChange={(e) => onInputChange(e, "url")}
        />
      </TableCell>
      <TableCell>
        <Input
          placeholder="phone"
          value={phone}
          onChange={(e) => onInputChange(e, "phone")}
        />
      </TableCell>
      <TableCell>
        <Input
          placeholder="manager"
          value={manager}
          onChange={(e) => onInputChange(e, "manager")}
        />
      </TableCell>
      <TableCell>
        <Input
          placeholder="managerPhone"
          value={managerPhone}
          onChange={(e) => onInputChange(e, "managerPhone")}
        />
      </TableCell>
      <TableCell>
        <div>{company?.adsCount || 0}</div>
      </TableCell>
      <TableCell>
        <div>{dayjs(company?.createdAt).format("YY.MM.DD")}</div>
      </TableCell>
      <TableCell>
        <Flex gap={theme.spacing[4]}>
          <Button text="제출" disabled={loading} onClick={onSubmit} />
          {!company && (
            <Button text="취소" disabled={loading} onClick={onCancel} />
          )}
        </Flex>
      </TableCell>
    </tr>
  );
}

export default AdsCompanyRow;
