import { useEffect, useState } from "react";

interface State {
  id: string;
  names: {
    [key: string]: {
      id?: string | null;
      languageCode?: string | null;
      name?: string;
    };
  };
  active: boolean;
  order: number;
}

const DEFAULT_STATE: State = {
  id: "",
  names: {},
  active: true,
  order: 0,
};

function useOptionCard({
  item,
}: {
  item?: {
    id: string;
    active: boolean;
    order: number;
    names?: Array<{
      id: string;
      languageCode: string;
      name: string;
    } | null> | null;
  } | null;
}) {
  const [state, setState] = useState({
    ...DEFAULT_STATE,
  });

  const [showLanguageCode, setShowLanguageCode] = useState(false);

  const [languageCode, setLanguageCode] = useState<string>("");

  useEffect(() => {
    if (item) {
      const { id, names, active, order } = item;

      const nameObj: {
        [key: string]: {
          id?: string | null;
          languageCode?: string | null;
          name?: string;
        };
      } = {};

      names?.forEach((name) => {
        nameObj[name!.languageCode] = {
          id: name!.id,
          languageCode: name!.languageCode,
          name: name!.name,
        };
      });

      setState({
        id,
        order,
        active,
        names: nameObj,
      });
    }
  }, [item]);

  function onInputChange(e: React.ChangeEvent<HTMLInputElement>, key: string) {
    const { value, checked } = e.target;

    setState((prev) => ({
      ...prev,
      [key]: key !== "active" ? value : checked,
    }));
  }

  function onNameChange(
    e: React.ChangeEvent<HTMLInputElement>,
    key: string,
    languageCode: string
  ) {
    const { value } = e.target;

    setState((prev) => ({
      ...prev,
      names: {
        ...prev.names,
        [languageCode]: {
          ...prev.names![languageCode],
          [key]: value,
        },
      },
    }));
  }

  function toggleShowLanguageCode() {
    setShowLanguageCode(!showLanguageCode);
  }

  function onLanguageCodeSelect(e: React.ChangeEvent<HTMLSelectElement>) {
    const { value } = e.target;

    setLanguageCode(value);
  }

  function onNameAdd() {
    setState((prev) => ({
      ...prev,
      names: {
        ...prev.names,
        [languageCode]: {
          id: null,
          languageCode,
          name: "",
        },
      },
    }));

    setShowLanguageCode(false);
  }

  function onAfterUpdate() {
    if (item) return;

    setState({ ...DEFAULT_STATE });
  }

  return {
    models: {
      state,
      languageCode,
      showLanguageCode,
    },
    operations: {
      onInputChange,
      onNameChange,
      toggleShowLanguageCode,
      onLanguageCodeSelect,
      onNameAdd,
      onAfterUpdate,
    },
  };
}

export default useOptionCard;
