import React from "react";
import useAdsList from "./AdsList.hook";
import Loader from "../../common/components/Loader";
import Grid from "../../common/components/Grid";
import theme from "../../../lib/theme";
import Flex from "../../common/components/Flex";
import Button from "../../common/components/Button";
import FetchMore from "../../common/components/FetchMore";
import AdCard from "./AdCard";

function AdsList() {
  const {
    refs: { fetchMoreRef },
    models: { loading, data, showNewRow },
    operations: { toggleShowNewRow },
  } = useAdsList();

  if (loading) return <Loader />;

  return (
    <>
      <Flex justifyContent="flex-end">
        <Button text="추가하기" onClick={() => toggleShowNewRow(true)} />
      </Flex>
      <Grid
        overflow="auto"
        gridTemplateColumns="repeat(4, 1fr)"
        width="100%"
        height="auto"
        gap={theme.spacing[12]}
        gridAutoRows="auto"
        alignItems="stretch"
      >
        {showNewRow && (
          <AdCard ad={null} onCancel={() => toggleShowNewRow(false)} />
        )}
        {data?.map((ad) => (
          <AdCard ad={ad} key={ad?.id} />
        ))}
      </Grid>
      <FetchMore fetchMoreRef={fetchMoreRef} />
    </>
  );
}

export default AdsList;
